import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loaiAPI, loaiAllAPI } from "../../services/API_Config";

const API_LoaiSuper = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [tenLoai, setTenLoai] = useState("");
  const [phuLuc, setPhuLuc] = useState("");

  const [editIdLoai, setEditIdLoai] = useState("");
  const [editTenLoai, setEditTenLoai] = useState("");
  const [editPhuLuc, setEditPhuLuc] = useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(loaiAllAPI)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    handleShowThem();
    if (!tenLoai) {
      // toast.error("Vui lòng nhập tên loại.");
      return;
    }
    const url = loaiAPI;
    const data = {
      TenLoai: tenLoai,
      PhuLuc: phuLuc,
    };

    axios
      .post(url, data)
      .then((result) => {
        if (result.status === 201) {
          handleCloseThem();
          getData();
          clear();
          toast.success("Loại đã được thêm");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleEdit = (idLoai) => {
    handleShow();
    axios
      .get(`${loaiAPI}/${idLoai}`)
      .then((result) => {
        setEditTenLoai(result.data.tenLoai);
        setEditPhuLuc(result.data.phuLuc);
        setEditIdLoai(idLoai);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleUpdate = () => {
    handleShow();
    const url = `${loaiAPI}/${editIdLoai}`;
    const data = {
      TenLoai: editTenLoai,
      PhuLuc: editPhuLuc,
      idLoai: editIdLoai,
    };

    axios
      .put(url, data)
      .then((result) => {
        if (result.status === 200) {
          handleClose();
          getData();
          clear();
          toast.success("Loại đã được Cập nhật");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDelete = (idLoai) => {
    if (window.confirm("Bạn chắc chắn muốn xóa?")) {
      const url = `${loaiAPI}/${idLoai}`;

      const loaiToDelete = data.find((item) => item.idLoai === idLoai);

      if (loaiToDelete) {
        loaiToDelete.xoa = true;

        axios
          .put(url, loaiToDelete)
          .then((result) => {
            if (result.status === 200) {
              toast.success("Loại đã được đánh dấu xóa");
              getData();
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
  };

  const handleRestore = (idLoai) => {
    if (window.confirm("Bạn chắc chắn muốn khôi phục?") === true) {
      const url = `${loaiAPI}/${idLoai}`;
      // Lấy dữ liệu gốc từ state data dựa vào id
      const loaiToRestore = data.find((item) => item.idLoai === idLoai);

      if (loaiToRestore) {
        // Thực hiện cập nhật trạng thái xóa trong dữ liệu gốc
        loaiToRestore.xoa = false;
        // Gửi yêu cầu cập nhật lên server
        axios
          .put(url, loaiToRestore)
          .then((result) => {
            if (result.status === 200) {
              toast.success("Loại đã được khôi phục");
              getData();
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      }
    }
  };

  const clear = () => {
    setTenLoai("");
    setPhuLuc("");
    setEditTenLoai("");
    setEditPhuLuc("");
    setEditIdLoai(0);
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Modal show={showThem} onHide={handleCloseThem} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Thêm loại</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <textarea
                  className="form-control"
                  placeholder="Nhập loại vào"
                  value={tenLoai}
                  onChange={(e) => setTenLoai(e.target.value)}
                  rows={4} // Điều này xác định chiều cao ban đầu của vùng nhập liệu và có thể được điều chỉnh
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập phụ lục vào"
                  value={phuLuc}
                  onChange={(e) => setPhuLuc(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThem}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!tenLoai || !phuLuc}
          >
            Thêm loại
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={2}>
            <button
              className="btn btn-outline-primary btn-block"
              onClick={() => handleSave()}
            >
              Thêm loại
            </button>
          </Col>
        </Row>
      </Container>
      <br />
      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>ID</th>
            <th>Tên loại</th>
            <th>Phụ lục</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {item.idLoai} </td>
                  <td className="custom-text-left"> {item.tenLoai} </td>
                  <td> {item.phuLuc} </td>
                  <td colSpan={2}>
                    {!item.xoa && (
                      <>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() => handleEdit(item.idLoai)}
                        >
                          Sửa{" "}
                        </button>

                        <button
                          className="btn btn-outline-danger"
                          onClick={() => handleDelete(item.idLoai)}
                        >
                          Xóa
                        </button>
                      </>
                    )}
                    {/* Khôi phục */}
                    {item.xoa ? (
                      <button
                        className="btn btn-warning"
                        onClick={() => handleRestore(item.idLoai)}
                      >
                        Khôi phục
                      </button>
                    ) : null}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa Loại</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <textarea
                  className="form-control"
                  placeholder="Nhập loại vào"
                  value={editTenLoai}
                  onChange={(e) => setEditTenLoai(e.target.value)}
                  rows={4} // Điều này xác định chiều cao ban đầu của vùng nhập liệu và có thể được điều chỉnh
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Nhập phụ lục vào"
                  value={editPhuLuc}
                  onChange={(e) => setEditPhuLuc(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_LoaiSuper;
