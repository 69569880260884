import React from "react";
import "../Styles/StyleComponents.scss";
import "../Styles/InPhuLuc.css";
import API_InPhuLucTheoChiBo from "./API_InPhuLucTheoChiBo";

function InPhuLucTheoChiBo() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_InPhuLucTheoChiBo />
    </div>
  );
}

export default InPhuLucTheoChiBo;
