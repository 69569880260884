import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { loaiAPI, noiDungAPI, noiDungAllAPI } from "../../services/API_Config";

const API_NoiDungSuper = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [tenNoiDung, setTenNoiDung] = useState("");

  const [editIdNoiDung, setEditIdNoiDung] = useState("");
  const [editTenNoiDung, setEditTenNoiDung] = useState("");
  const [selectedLoaiForNoiDung, setSelectedLoaiForNoiDung] = useState("");

  const [loaiDataView, setLoaiDataView] = useState({});
  const [loaiData, setLoaiData] = useState([]);
  const [data, setData] = useState([]);

  /** Xử lý phân trang */
  // Biến trạng thái để theo dõi trang hiện tại và số lượng mục trên mỗi trang
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // Hàm để tính vị trí bắt đầu và kết thúc của danh sách hiện tại dựa trên currentPage và itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Nút điều hướng phân trang (trước, sau, số trang) và xử lý sự kiện
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Chức năng tìm kiếm
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState(0);

  // Số lượng trang tối đa muốn hiển thị (ví dụ: 5 trang)
  const maxPages = 5;
  // Tạo danh sách các trang cần hiển thị
  let pageNumbers = [];
  if (totalPages <= maxPages) {
    pageNumbers = [...Array(totalPages).keys()].map((number) => number + 1);
  } else {
    const halfMax = Math.floor(maxPages / 2);
    const firstPage = Math.max(currentPage - halfMax, 1);
    const lastPage = Math.min(currentPage + halfMax, totalPages);
    pageNumbers = [...Array(lastPage - firstPage + 1).keys()].map(
      (number) => firstPage + number
    );
  }
  // Thêm các nút điều hướng trước và sau
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  /** Lọc theo loại */
  const [selectedLoaiFilter, setSelectedLoaiFilter] = useState("");

  useEffect(() => {
    getData();
    getLoai();
  }, []);

  const getData = () => {
    axios
      .get(noiDungAllAPI)
      .then((result) => {
        setData(result.data);

        result.data.forEach((noiDung) => {
          axios
            .get(`${loaiAPI}/${noiDung.idLoai}`)
            .then((loaiResult) => {
              setLoaiDataView((prevData) => ({
                ...prevData,
                [noiDung.idNoiDung]: loaiResult.data,
              }));
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearch(0);
    console.log(searchTerm);
  };
  const handleSearch = (event) => {
    setSearch(1);
    console.log(searchTerm);
  };

  const getLoai = async () => {
    try {
      const result = await axios.get(loaiAPI);
      setLoaiData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSave = async () => {
    handleShowThem();
    if (!tenNoiDung || !selectedLoaiForNoiDung) {
      // toast.error("Vui lòng nhập tên nội dung và chọn loại.");
      return;
    }

    const url = noiDungAPI;
    const data = {
      TenNoiDung: tenNoiDung,
      IdLoai: selectedLoaiForNoiDung,
    };

    try {
      const result = await axios.post(url, data);
      if (result.status === 201) {
        getData();
        setSelectedLoaiForNoiDung("");
        clear();
        toast.success("Nội dung đã được thêm");
        handleCloseThem();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEdit = async (idNoiDung) => {
    handleShow();
    try {
      const result = await axios.get(`${noiDungAPI}/${idNoiDung}`);
      setEditIdNoiDung(idNoiDung);
      setEditTenNoiDung(result.data.tenNoiDung);
      setSelectedLoaiForNoiDung(result.data.idLoai);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleUpdate = async () => {
    if (!editTenNoiDung || !selectedLoaiForNoiDung) {
      toast.error("Vui lòng nhập tên nội dung và chọn loại.");
      return;
    }

    try {
      const url = `${noiDungAPI}/${editIdNoiDung}`;
      const data = {
        idNoiDung: editIdNoiDung,
        tenNoiDung: editTenNoiDung,
        idLoai: selectedLoaiForNoiDung,
      };

      const result = await axios.put(url, data);
      if (result.status === 200) {
        handleClose();
        getData();
        clear();
        toast.success("Nội dung đã được cập nhật");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async (idNoiDung) => {
    const shouldDelete = window.confirm("Bạn chắc chắn muốn xóa?");

    if (!shouldDelete) {
      return;
    }

    const url = `${noiDungAPI}/${idNoiDung}`;

    try {
      // Lấy index của nội dung trong mảng data
      const index = data.findIndex((item) => item.idNoiDung === idNoiDung);

      if (index !== -1) {
        // Tạo một bản sao của đối tượng nội dung để thực hiện cập nhật trạng thái xóa
        const updatedNoiDung = { ...data[index], xoa: true };

        // Gửi yêu cầu cập nhật lên server
        const response = await axios.put(url, updatedNoiDung);

        if (response.status === 200) {
          // Cập nhật lại mảng data sau khi xóa thành công
          const updatedData = [...data];
          updatedData[index] = updatedNoiDung;

          // Cập nhật state hoặc gọi hàm để cập nhật dữ liệu
          setData(updatedData); // Giả sử setData là hàm cập nhật state
          getData(); // Load lại data (chỉ hiển thị nội dung có cột xóa là false)

          // Hiển thị thông báo thành công
          toast.success("Nội dung đã được đánh dấu xóa");
        }
      }
    } catch (error) {
      toast.error("Đã xảy ra lỗi khi xóa nội dung");
    }
  };

  const handleRestore = async (idNoiDung) => {
    const shouldRestore = window.confirm("Bạn chắc chắn muốn khôi phục?");

    if (!shouldRestore) {
      return;
    }

    const url = `${noiDungAPI}/${idNoiDung}`;

    try {
      // Lấy index của nội dung trong mảng data
      const index = data.findIndex((item) => item.idNoiDung === idNoiDung);

      if (index !== -1) {
        // Tạo một bản sao của đối tượng nội dung để thực hiện cập nhật trạng thái xóa
        const updatedNoiDung = { ...data[index], xoa: false };

        // Gửi yêu cầu cập nhật lên server
        const response = await axios.put(url, updatedNoiDung);

        if (response.status === 200) {
          // Cập nhật lại mảng data sau khi khôi phục thành công
          const updatedData = [...data];
          updatedData[index] = updatedNoiDung;

          // Cập nhật state hoặc gọi hàm để cập nhật dữ liệu
          setData(updatedData); // Giả sử setData là hàm cập nhật state
          getData(); // Load lại data

          // Hiển thị thông báo thành công
          toast.success("Nội dung đã được khôi phục");
        }
      }
    } catch (error) {
      toast.error("Đã xảy ra lỗi khi khôi phục nội dung");
    }
  };

  const clear = () => {
    setTenNoiDung("");
    setEditIdNoiDung(0);
    setEditTenNoiDung("");
    setSelectedLoaiForNoiDung("");
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Modal show={showThem} onHide={handleCloseThem} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Thêm nội dung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <textarea
                  className="form-control"
                  placeholder="Nhập nội dung vào"
                  value={tenNoiDung}
                  onChange={(e) => setTenNoiDung(e.target.value)}
                  rows={4} // Điều này xác định chiều cao ban đầu của vùng nhập liệu và có thể được điều chỉnh
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <select
                  className="form-select"
                  value={selectedLoaiForNoiDung}
                  onChange={(e) => setSelectedLoaiForNoiDung(e.target.value)}
                >
                  <option value="">Chọn loại</option>
                  {loaiData.map((loai) => (
                    <option key={loai.idLoai} value={loai.idLoai}>
                      {loai.tenLoai}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThem}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!tenNoiDung || !selectedLoaiForNoiDung}
          >
            Thêm nội dung
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Hiển thị */}
      <Container>
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <button
              className="btn btn-outline-primary w-100"
              onClick={() => handleSave()}
            >
              Thêm nội dung
            </button>
          </Col>
          <Col xs={0} md={3}></Col>
          <Col xs={0} md={3}></Col>
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Nhập tên nội dung cần tìm"
                value={searchTerm}
                onChange={(e) => {
                  handleChangeSearch(e);
                  setItemsPerPage(data.length);
                }}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  setSelectedLoaiFilter("");
                  setItemsPerPage(data.length);
                  setSearch(1);
                  handleSearch(e);
                }}
              >
                <BsSearch />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
              }}
              disabled={selectedLoaiFilter === "" ? false : true}
            >
              <option value={10}>10 mục/trang</option>
              <option value={20}>20 mục/trang</option>
              <option value={50}>50 mục/trang</option>
              {/* Thêm tất cả để lọc toàn bô */}
              <option value={data.length}>Tất cả mục</option>
            </select>
          </Col>
          <Col xs={12} md={6}>
            <div className="pagination">
              <Button
                variant="outline-info"
                onClick={() => prevPage()}
                disabled={currentPage === 1}
              >
                Trang trước
              </Button>{" "}
              &nbsp;
              {pageNumbers.map((pageNumber) => (
                <Button
                  key={pageNumber}
                  variant={`outline-info ${
                    currentPage === pageNumber ? "active" : ""
                  }`}
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </Button>
              ))}
              &nbsp;
              <Button
                variant="outline-info"
                onClick={() => nextPage()}
                disabled={indexOfLastItem >= data.length}
              >
                Trang sau
              </Button>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={selectedLoaiFilter}
              onChange={(e) => {
                setSelectedLoaiFilter(e.target.value);
                setItemsPerPage(data.length);
                setSearchTerm("");
              }}
            >
              <option value="">Chọn Loại (Lọc)</option>
              {loaiData.map((loai) => (
                <option key={loai.idLoai} value={loai.idLoai}>
                  {loai.tenLoai}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>ID</th>
            <th>Tên nội dung</th>
            <th>Loại</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            currentItems
              .filter((item) => {
                // Kiểm tra nếu selectedLoaiFilter là trống hoặc khớp với idLoai của mục
                return (
                  selectedLoaiFilter === "" ||
                  item.idLoai === parseInt(selectedLoaiFilter)
                );
              })
              .filter((item) => {
                // Tìm kiếm theo tên nội dung
                return searchTerm !== ""
                  ? item.tenNoiDung
                      .toLowerCase()
                      .normalize("NFC")
                      .includes(searchTerm.toLowerCase().normalize("NFC"))
                  : searchTerm === "";
              })

              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {item.idNoiDung} </td>
                    <td className="custom-text-left"> {item.tenNoiDung} </td>
                    <td className="custom-text-left">
                      {loaiDataView[item.idNoiDung]
                        ? loaiDataView[item.idNoiDung].tenLoai
                        : ""}{" "}
                      {/* Hiển thị thuộc tính 'tenLoai' */}
                    </td>
                    <td colSpan={2}>
                      {!item.xoa && (
                        <>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => handleEdit(item.idNoiDung)}
                          >
                            Sửa
                          </button>

                          <button
                            className="btn btn-outline-danger"
                            onClick={() => handleDelete(item.idNoiDung)}
                          >
                            Xóa
                          </button>
                        </>
                      )}
                      {/* Khôi phục */}
                      {item.xoa ? (
                        <button
                          className="btn btn-warning"
                          onClick={() => handleRestore(item.idNoiDung)}
                        >
                          Khôi phục
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan={5}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa nội dung</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <textarea
                  className="form-control"
                  placeholder="Nhập nội dung vào"
                  value={editTenNoiDung}
                  onChange={(e) => setEditTenNoiDung(e.target.value)}
                  rows={4} // Điều này xác định chiều cao ban đầu của vùng nhập liệu và có thể được điều chỉnh
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <select
                  className="form-select"
                  value={selectedLoaiForNoiDung}
                  onChange={(e) => setSelectedLoaiForNoiDung(e.target.value)}
                >
                  <option value="">Chọn loại</option>
                  {loaiData.map((loai) => (
                    <option key={loai.idLoai} value={loai.idLoai}>
                      {loai.tenLoai}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_NoiDungSuper;
