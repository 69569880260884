import React from "react";
import "../Styles/StyleComponents.scss";
import "../Styles/InPhuLuc.css";
import API_TongHopDanhGia from "./API_TongHopDanhGia";

function TongHopDanhGia() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_TongHopDanhGia />
    </div>
  );
}

export default TongHopDanhGia;
