import React from "react";
import "../Styles/StyleComponents.scss";
import API_DonVi from "./API_DonVi";

function DonVi() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_DonVi />
    </div>
  );
}

export default DonVi;
