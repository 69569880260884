import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import { taiKhoanAPI } from "../../services/API_Config";

const API_ThayDoiMatKhau = () => {
  // Thay đổi mật khẩu
  const [selectedUser, setSelectedUser] = useState("");
  const [editPassWord, setEditPassWord] = useState("");
  const [editNewPassWord, setEditNewPassWord] = useState("");
  const [editConfirmPassWord, setEditConfirmPassWord] = useState("");

  // Ẩn, hiện password
  const [showPasswordOld, setShowPasswordOld] = useState(false); // Thêm state để theo dõi việc hiển thị mật khẩu
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  useEffect(() => {
    setSelectedUser(sessionStorage.getItem("taikhoan"));
  }, []);

  const handleUpdateMatKhau = (e) => {
    e.preventDefault();

    // Kiểm tra xem mật khẩu mới và mật khẩu xác nhận có trùng nhau không
    if (editNewPassWord !== editConfirmPassWord) {
      toast.error("Mật khẩu xác nhận không trùng khớp");
      return;
    }
    const url = `${taiKhoanAPI}/reset`;
    const data = {
      username: selectedUser, // Đổi tên biến thành "username" để phù hợp với yêu cầu API
      password: editPassWord, // Sử dụng mật khẩu cũ
      newpassword: editNewPassWord, // Mật khẩu mới
    };

    axios
      .put(url, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success("Mật khẩu đã được cập nhật");
          clear();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const clear = () => {
    setEditPassWord("");
    setEditNewPassWord("");
    setEditConfirmPassWord("");
  };

  return (
    <>
      <Container>
        <Form onSubmit={handleUpdateMatKhau}>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <label htmlFor="matKhau">THAY ĐỔI MẬT KHẨU</label>
            </Col>
          </Row>

          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <div className="d-flex align-items-center">
                <input
                  type={showPasswordOld ? "text" : "password"}
                  className="form-control"
                  placeholder="Nhập mật khẩu cũ"
                  required="required"
                  value={editPassWord}
                  onChange={(e) => {
                    setEditPassWord(e.target.value);
                  }}
                />
                <span
                  className="ms-2"
                  onClick={() => setShowPasswordOld(!showPasswordOld)}
                >
                  <i>
                    {showPasswordOld ? <Icons.FaEyeSlash /> : <Icons.FaEye />}
                  </i>
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <div className="d-flex align-items-center">
                <input
                  type={showPasswordNew ? "text" : "password"}
                  className="form-control"
                  placeholder="Nhập mật khẩu mới"
                  required="required"
                  value={editNewPassWord}
                  onChange={(e) => setEditNewPassWord(e.target.value)}
                />
                <span
                  className="ms-2"
                  onClick={() => setShowPasswordNew(!showPasswordNew)}
                >
                  <i>
                    {showPasswordNew ? <Icons.FaEyeSlash /> : <Icons.FaEye />}
                  </i>
                </span>
              </div>
            </Col>
          </Row>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <div className="d-flex align-items-center">
                <input
                  type={showPasswordConfirm ? "text" : "password"}
                  className="form-control"
                  placeholder="Xác nhận mật khẩu mới"
                  required="required"
                  value={editConfirmPassWord}
                  onChange={(e) => setEditConfirmPassWord(e.target.value)}
                />
                <span
                  className="ms-2"
                  onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                >
                  <i>
                    {showPasswordConfirm ? (
                      <Icons.FaEyeSlash />
                    ) : (
                      <Icons.FaEye />
                    )}
                  </i>
                </span>
              </div>
            </Col>
          </Row>
          <Button variant="outline-primary" type="submit">
            Lưu thay đổi
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default API_ThayDoiMatKhau;
