import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import { khoaMoSoAPI } from "../../services/API_Config";

const API_KhoaMoSo = () => {
  const [data, setData] = useState([]);
  const [selectedNam, setSelectedNam] = useState();
  const [selectedIdPhong, setSelectedIdPhong] = useState();
  const [unload, setUnLoad] = useState(0);

  // Sử dụng state để lưu trữ tháng và năm hiện tại
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();

  // Hàm lấy tháng, nămm hiện tại
  const getCurrentMonth = () => {
    // Lấy ngày hiện tại
    const currentDate = new Date();
    // Lấy tháng hiện tại
    const currentMonth = currentDate.getMonth() + 1; //getMonth() trả về từ 0-11, nên cộng thêm 1 để lấy tháng từ 1-12
    // Lấy năm hiện tại
    const currentYear = currentDate.getFullYear();    
    return { currentMonth, currentYear };
  };

  useEffect(() => {
    setSelectedIdPhong(sessionStorage.getItem("id_phongkhoa"));
    
    // Gọi hàm để lấy tháng và năm hiện tại
    const { currentMonth, currentYear } = getCurrentMonth();
    setCurrentMonth(currentMonth);
    setCurrentYear(currentYear);
    
  }, []);

  const getData = () => {
    axios
      .get(`${khoaMoSoAPI}/${selectedNam}/${selectedIdPhong}`)
      .then((result) => {
        setData(result.data);
        console.log(result);
      })
      .catch((error) => {
        console.log(`Error fetching MoKhoaSo data: ${error}`);
        console.log(`${khoaMoSoAPI}/${selectedNam}/${selectedIdPhong}`);
      });
    setUnLoad(1);
  };

  // Hàm kiểm tra tháng hiện tại có phải là tháng đang xét không
  const isCurrentMonth = (month) => {
    return currentMonth === month && parseInt(selectedNam) === currentYear;
  };

  const handleSave = (thang) => {
    if (!isCurrentMonth(thang)) {
      
      toast.error("Bạn chỉ có thể mở khóa tháng hiện tại của năm hiện tại", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    const url = `${khoaMoSoAPI}`;
    const data = {
      Nam: parseInt(selectedNam),
      Thang: thang,
      TrangThai: true,
      TaiKhoanDuyet: sessionStorage.getItem("taikhoan"),
      //TaiKhoanDuyet: sessionStorage.getItem("hoten"),
      IdPhongKhoa: parseInt(selectedIdPhong),
    };
    const moKhoa = window.confirm("Bạn chắc chắn muốn mở khóa?");    
    if (moKhoa === true) {
      axios
        .post(url, data)
        .then((result) => {
          // if (result.status === 200) {
          // toast.success("Đã được mở khóa");
          console.log(data);
          getData();
          //  }
        })
        .catch((error) => {          
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          console.log(data);
        });
    }
  };
  
  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Container>
        <Row className="align-items-center mb-3">
          <Col xs={12} md={1}>
            <label>Năm:</label>
          </Col>
          <Col xs={12} md={2}>
            <select
              className="form-control"
              value={selectedNam}
              onChange={(e) => {
                setSelectedNam(e.target.value);
              }}
            >
              <option value="">Chọn năm</option>
              {(() => {
                const options = [];
                for (let year = 2023; year <= 2040; year++) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={1}>
            <Button variant="outline-primary" onClick={() => getData()}>
              Xem{" "}
            </Button>{" "}
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            {/* <th>Năm</th> */}
            <th>Tháng</th>
            <th>Trạng thái</th>
            <th>Ngày duyệt</th>
            <th>Tài khoản duyệt</th>
            {/* <th>ID phòng khoa</th> */}
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0
            ? (() => {
                const rows = [];
                for (let i = 1; i <= 12; i++) {
                  let found = false;
                  for (let index = 0; index < data.length; index++) {
                    const item = data[index];
                    if (item.thang === i) {
                      rows.push(
                        <tr key={i}>
                          <td> {i} </td>
                          {/* <td> {item.nam} </td> */}
                          <td> {item.thang} </td>
                          <td> {item.trangThai ? "Mở" : "Khóa"} </td>
                          <td> {item.ngayDuyet} </td>
                          <td> {item.taiKhoanDuyet} </td>
                          {/* <td> {item.idPhongKhoa} </td> */}
                          <td>
                            {/* Đã mở */}
                            <button
                              className="btn btn-outline-warning"
                              disabled
                            >
                              <Icons.FaUnlock />{" "}
                            </button>{" "}
                            {/* In*/}
                            {/* <button className="btn btn-outline-info">
                              <Icons.FaPrint />{" "}
                            </button>{" "} */}
                          </td>
                        </tr>
                      );
                      found = true;
                    }
                  }
                  //    let item = data.find((item) => item.thang === i) || {
                  //       trangThai: false,
                  //     };
                  if (found === false) {
                    rows.push(
                      <tr key={i}>
                        <td> {i} </td>
                        {/* <td> </td> */}
                        <td> {i} </td>
                        <td> Khóa </td>
                        <td> </td>
                        <td> </td>
                        {/* <td> </td> */}
                        <td>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => handleSave(i)}
                          >
                            {/* Khóa */}
                            <Icons.FaLock />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                }
                return rows;
              })()
            : (() => {
                const rows = [];
                if (unload !== 0) {
                  for (let i = 1; i <= 12; i++) {
                    rows.push(
                      <tr key={i}>
                        <td> {i} </td>
                        {/* <td> </td> */}
                        <td> {i} </td>
                        <td> Khóa </td>
                        <td> </td>
                        <td> </td>
                        {/* <td> </td> */}
                        <td>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => handleSave(i)}
                          >
                            {/* {item.trangThai ? "Đã mở" : "Mở khóa"} */}
                            {/* Mở khóa */}
                            <Icons.FaLock /> {/* Sử dụng biểu tượng khóa */}
                          </button>
                        </td>
                      </tr>
                    );
                  }
                  return rows;
                }
              })()}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default API_KhoaMoSo;
