import React from "react";
import "../Styles/StyleComponents.scss";
import API_NoiDung from "./API_NoiDung";

function NoiDung() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_NoiDung />
    </div>
  );
}

export default NoiDung;
