import React from "react";
// import "../Styles/StyleComponents.scss";
import API_Loai from "./API_Loai.js";

function Loai() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_Loai />
    </div>
  );
}

export default Loai;
