// Get API
export const API = process.env.REACT_APP_API;
//export const API = "https://localhost:7023/api/" || process.env.REACT_APP_API;

export const login = "Login";
export const taiKhoan = "TaiKhoan";
export const donVi = "DonVi";
export const khoaMoSo = "KhoaMoSo";
export const loai = "Loai";
export const noiDung = "NoiDung";
export const phanQuyen = "PhanQuyen";
export const phongKhoa = "PhongKhoa";
export const soTuTheoDoi = "SoTuTheoDoi";
export const noiDungSoTuTheoDoi = "NoiDungSoTuTheoDoi";
export const quyenTaiKhoan = "QuyenTaiKhoan";
export const inPhuLucChiBo = "InPhuLucChiBo";
export const inPhuLucDangVien = "InPhuLucDangVien";

// Super
export const taiKhoanAll = "TaiKhoan/all";
export const donViAll = "DonVi/all";
export const phongKhoaAll = "PhongKhoa/all";
export const loaiAll = "Loai/all";
export const noiDungAll = "NoiDung/all";

export const loginAPI = API + login;
export const taiKhoanAPI = API + taiKhoan;
export const donViAPI = API + donVi;
export const khoaMoSoAPI = API + khoaMoSo;
export const loaiAPI = API + loai;
export const noiDungAPI = API + noiDung;
export const phanQuyenAPI = API + phanQuyen;
export const phongKhoaAPI = API + phongKhoa;
export const soTuTheoDoiAPI = API + soTuTheoDoi;
export const noiDungSoTuTheoDoiAPI = API + noiDungSoTuTheoDoi;
export const quyenTaiKhoanAPI = API + quyenTaiKhoan;
export const inPhuLucDangVienAPI = API + inPhuLucDangVien;

// Super admin
export const taiKhoanAllAPI = API + taiKhoanAll;
export const donViAllAPI = API + donViAll;
export const phongKhoaAllAPI = API + phongKhoaAll;
export const loaiAllAPI = API + loaiAll;
export const noiDungAllAPI = API + noiDungAll;
