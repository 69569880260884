import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  donViAPI,
  phongKhoaAPI,
  phongKhoaAllAPI,
} from "../../services/API_Config";

const API_PhongKhoaSuper = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [tenPhongKhoa, setTenPhongKhoa] = useState("");

  const [editIdPhongKhoa, setEditIdPhongKhoa] = useState("");
  const [editTenPhongKhoa, setEditTenPhongKhoa] = useState("");

  const [donViDataView, setDonViDataView] = useState({}); // Lấy tên đơn vị trong bảng phòng khoa để xử lý hiển thị
  const [donViData, setDonViData] = useState([]); // Lấy tên đơn vị trong bảng phòng khoa để xử lý thêm, sửa

  const [selectedDonViForPhongKhoa, setSelectedDonViForPhongKhoa] =
    useState("");

  // Mảng chứa dữ liệu Phòng khoa được lấy từ API. Ban đầu, nó sẽ được set là một mảng trống.
  const [data, setData] = useState([]);

  /** Xử lý phân trang */
  // Biến trạng thái để theo dõi trang hiện tại và số lượng mục trên mỗi trang
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // Hàm để tính vị trí bắt đầu và kết thúc của danh sách hiện tại dựa trên currentPage và itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Nút điều hướng phân trang (trước, sau, số trang) và xử lý sự kiện
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Chức năng tìm kiếm
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState(0);

  // Số lượng trang tối đa muốn hiển thị (ví dụ: 5 trang)
  const maxPages = 5;
  // Tạo danh sách các trang cần hiển thị
  let pageNumbers = [];
  if (totalPages <= maxPages) {
    pageNumbers = [...Array(totalPages).keys()].map((number) => number + 1);
  } else {
    const halfMax = Math.floor(maxPages / 2);
    const firstPage = Math.max(currentPage - halfMax, 1);
    const lastPage = Math.min(currentPage + halfMax, totalPages);
    pageNumbers = [...Array(lastPage - firstPage + 1).keys()].map(
      (number) => firstPage + number
    );
  }
  // Thêm các nút điều hướng trước và sau
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  /** Lọc theo loại */
  const [selectedDonViFilter, setSelectedDonViFilter] = useState("");

  // Hook này được sử dụng để gọi hàm getData khi component được render lần đầu tiên
  useEffect(() => {
    getData(); // Gọi API lấy dữ liệu Phòng khoa
    getDonVi(); // Gọi API lấy danh sách đơn vị
  }, []);

  const getData = () => {
    axios
      .get(phongKhoaAllAPI)
      .then((result) => {
        setData(result.data);
        // Lấy thông tin đơn vị cho mỗi bản ghi Phòng khoa
        result.data.forEach((phongKhoa) => {
          axios
            .get(`${donViAPI}/${phongKhoa.idDonVi}`)
            .then((donViResult) => {
              setDonViDataView((prevData) => ({
                ...prevData,
                [phongKhoa.idPhongKhoa]: donViResult.data,
              }));
              console.log(donViResult);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearch(0);
    console.log(searchTerm);
  };
  const handleSearch = (event) => {
    setSearch(1);
    console.log(searchTerm);
  };

  /**
   * Hàm lấy data của đơn vị
   */
  const getDonVi = () => {
    axios
      .get(donViAPI)
      .then((result) => {
        setDonViData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    handleShowThem();
    if (!tenPhongKhoa || !selectedDonViForPhongKhoa) {
      // toast.error("Vui lòng nhập tên phòng khoa và chọn đơn vị.");
      return;
    }

    const url = phongKhoaAPI;
    const data = {
      TenPhongKhoa: tenPhongKhoa,
      IdDonVi: selectedDonViForPhongKhoa,
    };

    axios
      .post(url, data)
      .then((result) => {
        if (result.status === 201) {
          getDonVi(); // Gọi hàm mới để cập nhật danh sách đơn vị
          getData(); // Cập nhật danh sách phòng khoa và dữ liệu khác
          clear();
          setSelectedDonViForPhongKhoa(""); // Đặt đơn vị thành lựa chọn rỗng sau khi thêm
          toast.success("Chi bộ đã được thêm", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleCloseThem();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  /**
   * Khi người dùng muốn sửa thông tin của một Phòng khoa,
   * hàm này sẽ gửi yêu cầu HTTP GET để lấy thông tin chi tiết của Phòng khoa dựa vào id được truyền vào.
   * Thông tin này sẽ được hiển thị trong modal để người dùng chỉnh sửa.
   */
  const handleEdit = (idPhongKhoa) => {
    handleShow();
    axios
      .get(`${phongKhoaAPI}/${idPhongKhoa}`)
      .then((result) => {
        setEditIdPhongKhoa(idPhongKhoa);
        setEditTenPhongKhoa(result.data.tenPhongKhoa);
        setSelectedDonViForPhongKhoa(result.data.idDonVi); // Chọn tên đơn vị
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  /**
   * Khi người dùng hoàn tất việc chỉnh sửa thông tin của Phòng khoa trong modal,
   * hàm này sẽ gửi yêu cầu HTTP PUT để cập nhật thông tin đã sửa.
   */
  const handleUpdate = async () => {
    try {
      const url = `${phongKhoaAPI}/${editIdPhongKhoa}`;
      const data = {
        idPhongKhoa: editIdPhongKhoa,
        tenPhongKhoa: editTenPhongKhoa,
        idDonVi: selectedDonViForPhongKhoa, // Thêm trường idDonVi nếu cần
      };

      const result = await axios.put(url, data);
      if (result.status === 200) {
        handleClose(); // Đóng hộp cập nhật
        getData();
        clear();
        toast.success("Chi bộ đã được Cập nhật", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }); // Sử dụng error.message để hiển thị thông báo lỗi cụ thể
    }
  };

  /**
   * Khi người dùng muốn xóa tạm một Phòng khoa (quản trị thường),
   * hàm này sẽ gửi yêu cầu HTTP PUT dựa vào id của Phòng khoa.
   * Trước khi xóa, một hộp thoại xác nhận sẽ được hiển thị để người dùng xác nhận.
   */
  const handleDelete = async (idPhongKhoa) => {
    const shouldDelete = window.confirm("Bạn chắc chắn muốn xóa?");

    if (!shouldDelete) {
      return;
    }

    const url = `${phongKhoaAPI}/${idPhongKhoa}`;

    try {
      // Lấy index của phòng khoa trong mảng data
      const index = data.findIndex((item) => item.idPhongKhoa === idPhongKhoa);

      if (index !== -1) {
        // Tạo một bản sao của đối tượng phòng khoa để thực hiện cập nhật trạng thái xóa
        const updatedPhongKhoa = { ...data[index], xoa: true };

        // Gửi yêu cầu cập nhật lên server
        const response = await axios.put(url, updatedPhongKhoa);

        if (response.status === 200) {
          // Cập nhật lại mảng data sau khi xóa thành công
          const updatedData = [...data];
          updatedData[index] = updatedPhongKhoa;

          // Cập nhật state hoặc gọi hàm để cập nhật dữ liệu
          setData(updatedData); // Giả sử setData là hàm cập nhật state
          getData(); // Load lại data (chỉ hiển thị phong khoa có cột xóa là false)

          // Hiển thị thông báo thành công
          toast.success("Chi bộ đã được đánh dấu xóa", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      toast.error("Đã xảy ra lỗi khi xóa chi bộ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleRestore = (idPhongKhoa) => {
    if (window.confirm("Bạn chắc chắn khôi phục?") === true) {
      const url = `${phongKhoaAPI}/${idPhongKhoa}`;

      // Lấy dữ liệu gốc từ state data dựa vào id
      const phongKhoaToRestore = data.find(
        (item) => item.idPhongKhoa === idPhongKhoa
      );

      if (phongKhoaToRestore) {
        // Thực hiện cập nhật trạng thái xóa trong dữ liệu gốc
        phongKhoaToRestore.xoa = false;

        // Gửi yêu cầu cập nhật lên server
        axios
          .put(url, phongKhoaToRestore)
          .then((result) => {
            if (result.status === 200) {
              //success
              toast.success("Chi bộ đã được khôi phục", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              getData();
            }
          })
          .catch((error) => {
            toast.error(error, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      }
    }
  };

  /**
   * Hàm này dùng để xóa rỗng các trường dữ liệu nhập liệu và các biến trạng thái liên quan sau khi hoàn tất thêm hoặc sửa.
   */
  const clear = () => {
    setTenPhongKhoa("");
    setEditIdPhongKhoa(0);
    setEditTenPhongKhoa("");
    setSelectedDonViForPhongKhoa(""); // Đặt đơn vị thành lựa chọn rỗng sau khi hoàn tất thêm hoặc sửa
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Modal show={showThem} onHide={handleCloseThem}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm chi bộ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập chi bộ vào"
                  value={tenPhongKhoa}
                  onChange={(e) => setTenPhongKhoa(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <select
                  className="form-select"
                  value={selectedDonViForPhongKhoa}
                  onChange={(e) => setSelectedDonViForPhongKhoa(e.target.value)}
                >
                  <option value="">Chọn đơn vị</option>
                  {donViData.map((donVi) => (
                    <option key={donVi.idDonVi} value={donVi.idDonVi}>
                      {donVi.tenDonVi}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThem}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!tenPhongKhoa || !selectedDonViForPhongKhoa}
          >
            Thêm chi bộ
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Hiển thị */}
      <Container>
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <button
              className="btn btn-outline-primary w-100"
              onClick={() => handleSave()}
            >
              Thêm chi bộ
            </button>
          </Col>
          <Col xs={0} md={3}></Col>
          <Col xs={0} md={3}></Col>
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Nhập tên chi bộ cần tìm"
                value={searchTerm}
                onChange={(e) => {
                  handleChangeSearch(e);
                  setItemsPerPage(data.length);
                }}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  setSelectedDonViFilter("");
                  setItemsPerPage(data.length);
                  setSearch(1);
                  handleSearch(e);
                }}
              >
                <BsSearch />
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
              }}
              disabled={selectedDonViFilter === "" ? false : true}
            >
              <option value={10}>10 mục/trang</option>
              <option value={20}>20 mục/trang</option>
              <option value={50}>50 mục/trang</option>
              {/* Thêm tất cả để lọc toàn bô */}
              <option value={data.length}>Tất cả mục</option>
            </select>
          </Col>
          <Col xs={12} md={6}>
            <div className="pagination">
              <Button
                variant="outline-info"
                onClick={() => prevPage()}
                disabled={currentPage === 1}
              >
                Trang trước
              </Button>{" "}
              &nbsp;
              {pageNumbers.map((pageNumber) => (
                <Button
                  key={pageNumber}
                  variant={`outline-info ${
                    currentPage === pageNumber ? "active" : ""
                  }`}
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </Button>
              ))}
              &nbsp;
              <Button
                variant="outline-info"
                onClick={() => nextPage()}
                disabled={indexOfLastItem >= data.length}
              >
                Trang sau
              </Button>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={selectedDonViFilter}
              onChange={(e) => {
                setSelectedDonViFilter(e.target.value);
                setItemsPerPage(data.length);
                setSearchTerm("");
              }}
            >
              <option value="">Chọn Đơn vị (Lọc)</option>
              {donViData.map((donVi) => (
                <option key={donVi.idDonVi} value={donVi.idDonVi}>
                  {donVi.tenDonVi}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>ID</th>
            <th>Tên chi bộ</th>
            <th>Đơn vị</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            currentItems
              .filter((item) => {
                // Kiểm tra nếu selectedLoaiFilter là trống hoặc khớp với idLoai của mục
                return (
                  selectedDonViFilter === "" ||
                  item.idDonVi === parseInt(selectedDonViFilter)
                );
              })
              .filter((item) => {
                // Tìm kiếm theo tên nội dung
                return searchTerm !== ""
                  ? item.tenPhongKhoa
                      .toLowerCase()
                      .normalize("NFC")
                      .includes(searchTerm.toLowerCase().normalize("NFC"))
                  : searchTerm === "";
              })
              .map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {index + 1} </td>
                    <td> {item.idPhongKhoa} </td>
                    <td className="custom-text-left"> {item.tenPhongKhoa} </td>
                    <td className="custom-text-left">
                      {donViDataView[item.idPhongKhoa]?.tenDonVi || ""}{" "}
                      {/* Hiển thị tên đơn vị */}
                    </td>
                    <td colSpan={2}>
                      {!item.xoa && (
                        <>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => handleEdit(item.idPhongKhoa)}
                          >
                            Sửa{" "}
                          </button>

                          <button
                            className="btn btn-outline-danger"
                            onClick={() => handleDelete(item.idPhongKhoa)}
                          >
                            Xóa
                          </button>
                        </>
                      )}
                      {/* Khôi phục */}
                      {item.xoa ? (
                        <button
                          className="btn btn-warning"
                          onClick={() => handleRestore(item.idPhongKhoa)}
                        >
                          Khôi phục
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan={5}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa chi bộ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập tên vào"
                  value={editTenPhongKhoa}
                  onChange={(e) => setEditTenPhongKhoa(e.target.value)}
                />
              </Col>
              <Col>
                <select
                  className="form-select"
                  value={selectedDonViForPhongKhoa}
                  onChange={(e) => setSelectedDonViForPhongKhoa(e.target.value)}
                >
                  <option value="">Chọn đơn vị</option>
                  {donViData.map((donVi) => (
                    <option key={donVi.idDonVi} value={donVi.idDonVi}>
                      {donVi.tenDonVi}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_PhongKhoaSuper;
