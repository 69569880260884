import React from "react";
import "../Styles/StyleComponents.scss";
import API_KhoaMoSo from "../KhoaMoSo/API_KhoaMoSo";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

function KhoaMoSo() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_KhoaMoSo />
      {/* <ToastContainer /> */}
    </div>
  );
}

export default KhoaMoSo;
