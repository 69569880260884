import React, { useState, useEffect } from "react";
import Head from "./Head";
import Sidebar from "./Sidebar";
// import Sidebar, { checkUserRoles } from "./Sidebar"; // Import Sidebar.js và hàm checkUserRoles

import "../Home/Home.scss";
import backgroundImage from "../Images/danh-gia-chat-luong-dang-vien.png";
import API_TaiKhoan from "../TaiKhoan/TaiKhoan";
import API_DonVi from "../DonVi/DonVi";
import API_PhongKhoa from "../PhongKhoa/PhongKhoa";
import API_Loai from "../Loai/Loai";
import API_PhanQuyen from "../PhanQuyen/PhanQuyen";
import API_NoiDung from "../NoiDung/NoiDung";
import API_KhoaMoSo from "../KhoaMoSo/KhoaMoSo";
import API_SoTuTheoDoi from "../SoTuTheoDoi/SoTuTheoDoi";
import API_KiemDuyet from "../KiemDuyet/KiemDuyet";
import API_ThayDoiMatKhau from "../ThayDoiMatKhau/ThayDoiMatKhau";
import API_ThayDoiThongTin from "../ThayDoiThongTin/ThayDoiThongTin";
import API_InPhuLucChiBo from "../InPhuLucChiBo/InPhuLucChiBo";
import API_InPhuLucTheoChiBo from "../InPhuLucTheoChiBo/InPhuLucTheoChiBo";
import API_InPhuLucDangVien from "../InPhuLucDangVien/InPhuLucDangVien";
import API_TongHopDanhGia from "../TongHopDanhGia/TongHopDanhGia";
// Super
import API_TaiKhoanSuper from "../TaiKhoan/TaiKhoanSuper";
import API_DonViSuper from "../DonVi/DonViSuper";
import API_PhongKhoaSuper from "../PhongKhoa/PhongKhoaSuper";
import API_LoaiSuper from "../Loai/LoaiSuper";
import API_NoiDungSuper from "../NoiDung/NoiDungSuper";
//---
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { loginAPI, phongKhoaAPI } from "../../services/API_Config";
import { Container } from "react-bootstrap";

const Home = () => {
  // Lấy thông tin từ token
  const navigate = useNavigate();

  const [tenDonVi, setTenDonVi] = useState(""); // State để lưu trữ tên đơn vị

  const verify = async () => {
    const token = localStorage.getItem("token");
    let result = await axios
      .get(`${loginAPI}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        // Sau khi đăng nhập lấy 4 biến session để sử dụng
        sessionStorage.setItem("taikhoan", result.data.user);
        sessionStorage.setItem("hoten", result.data.hoten);
        sessionStorage.setItem("email", result.data.email);
        sessionStorage.setItem("id_phongkhoa", result.data.idphongkhoa);        
        getTenDonVi(result.data.user);// Gọi hàm lấy tên đơn vị
        // Kiểm tra quyền tài khoản trước khi load sidebar và cập nhật state isAdminSubMenuOpen, isPartySubMenuOpen, ...
      })
      .catch((error) => {
        //Bắt lỗi chưa xác thực thì refesh lại token
        console.log(error.response.status);
        if (error.response.status === 401) {
          console.log("Chưa xác thực");
          localStorage.removeItem("token");
          navigate("/");
        }
      });
  };

  // Sidebar
  const [selectedComponent, setSelectedComponent] = useState(null);
  const showComponent = (component) => {
    setSelectedComponent(component);
  };

  // Lấy tên đơn vị từ phòng - chi bô
  const getTenDonVi = (user) => {
    axios
      .get(`${phongKhoaAPI}/GetTenDonVi/${user}`)
      .then((result) => {
        // Hiển thị tên đơn vị (đảng bộ) của tài khoản khi đăng nhập
        const tenDonVi = result.data[0].tenDonVi;
        setTenDonVi(tenDonVi); // Cập nhật state tenDonVi

        sessionStorage.setItem("ten_dv", result.data[0].tenDonVi);
        sessionStorage.setItem("id_dv", result.data[0].idDonVi);
        sessionStorage.setItem("ten_cb", result.data[0].tenPhongKhoa);
        //setDonViData(result.data);
        console.log(result.data);
        console.log(sessionStorage.setItem("ten_dv", result.data[0].tenDonVi));
      })
      .catch((error) => {
        console.log(error + `${phongKhoaAPI}/GetTenDonVi/${user}`);
      });
  };

  // Gọi hàm openNav khi component được render lần đầu tiên
  useEffect(() => {
    //Kiểm tra nếu đăng nhập thì mới được vào trang này nếu không sẽ bị đẩy ra trang login
    if (localStorage.getItem("token") == null) {
      console.log("Chưa đăng nhập");
      setTimeout(() => navigate("/"), 10);
    } else {
      verify();
      // thêm
      getTenDonVi(sessionStorage.getItem("taikhoan"));
      openNav();
      // navigate("/Home"); // load để refresh 1 lần khi đã đăng nhập thành công!
    }
  }, []);

  const openNav = () => {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  };

  return (
    <>
      <div id="main">
        <Head />
        <Sidebar showComponent={showComponent} />
        <div className="center-content">
          <div
            className="background-image"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <div>
              <h3 className="custom-text-center">
                HỆ THỐNG ĐÁNH GIÁ ĐẢNG VIÊN, VIÊN CHỨC
              </h3>
              {/* <h3 className="custom-text-center">TRƯỜNG CAO ĐẲNG KIÊN GIANG</h3> */}
              <h3 className="custom-text-center">{tenDonVi}</h3>              
            </div>
            {selectedComponent === "TaiKhoan" && <API_TaiKhoan />}
            {selectedComponent === "DonVi" && <API_DonVi />}
            {selectedComponent === "PhongKhoa" && <API_PhongKhoa />}
            {selectedComponent === "PhanQuyen" && <API_PhanQuyen />}
            {selectedComponent === "Loai" && <API_Loai />}
            {selectedComponent === "NoiDung" && <API_NoiDung />}
            {selectedComponent === "KhoaMoSo" && <API_KhoaMoSo />}
            {selectedComponent === "SoTuTheoDoi" && <API_SoTuTheoDoi />}
            {selectedComponent === "KiemDuyet" && <API_KiemDuyet />}
            {selectedComponent === "ThayDoiMatKhau" && <API_ThayDoiMatKhau />}
            {selectedComponent === "ThayDoiThongTin" && <API_ThayDoiThongTin />}
            {selectedComponent === "InPhuLucChiBo" && <API_InPhuLucChiBo />}
            {selectedComponent === "InPhuLucTheoChiBo" && (
              <API_InPhuLucTheoChiBo />
            )}
            {selectedComponent === "InPhuLucDangVien" && (
              <API_InPhuLucDangVien />
            )}
            {selectedComponent === "TongHopDanhGia" && <API_TongHopDanhGia />}
            {/* Super */}
            {selectedComponent === "TaiKhoanSuper" && <API_TaiKhoanSuper />}
            {selectedComponent === "DonViSuper" && <API_DonViSuper />}
            {selectedComponent === "PhongKhoaSuper" && <API_PhongKhoaSuper />}
            {selectedComponent === "LoaiSuper" && <API_LoaiSuper />}
            {selectedComponent === "NoiDungSuper" && <API_NoiDungSuper />}
          </div>
        </div>
        <div className="footer-page">
          <p>
            Copyright &copy; 2023 Nguyễn Vũ Lâm, Nguyễn Quan Khánh, Lư Chân
            Thiện
          </p>
          {/* <ul>
            <li>
              <a href="#">Facebook</a>
            </li>
            <li>
              <a href="#">Twitter</a>
            </li>
            <li>
              <a href="#">Instagram</a>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default Home;
