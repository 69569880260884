import React from "react";
import { Helmet } from "react-helmet";
import "../Home/Home.scss"; // Import the SCSS file

const Head = () => {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>QUẢN LÝ ĐẢNG VIÊN</title>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
      />
    </Helmet>
  );
};

export default Head;
