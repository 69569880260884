import React from "react";
import "../Styles/StyleComponents.scss";
import API_PhongKhoa from "./API_PhongKhoa";

function PhongKhoa() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_PhongKhoa />
    </div>
  );
}

export default PhongKhoa;
