import React from "react";
import "../Styles/StyleComponents.scss";
import API_NoiDungSuper from "./API_NoiDungSuper";

function NoiDungSuper() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_NoiDungSuper />
    </div>
  );
}

export default NoiDungSuper;
