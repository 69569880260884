import React from "react";
import "../Styles/StyleComponents.scss";
import API_QuenMatKhau from "./API_QuenMatKhau";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function QuenMatKhau() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_QuenMatKhau />
      <ToastContainer />
    </div>
  );
}

export default QuenMatKhau;
