import React from "react";
import "../Styles/StyleComponents.scss";
import API_PhongKhoaSuper from "./API_PhongKhoaSuper";

function PhongKhoaSuper() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_PhongKhoaSuper />
    </div>
  );
}

export default PhongKhoaSuper;
