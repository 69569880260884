import React from "react";
import "../Styles/StyleComponents.scss";
import API_ThayDoiThongTin from "././API_ThayDoiThongTin";

function ThayDoiThongTin() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_ThayDoiThongTin />
    </div>
  );
}

export default ThayDoiThongTin;
