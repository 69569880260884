import React, { useState, useEffect, Fragment, useRef } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import { soTuTheoDoiAPI } from "../../services/API_Config";
import { loaiAPI, noiDungAPI } from "../../services/API_Config";
import ReactToPrint from "react-to-print";

const API_TongHopDanhGia = () => {
  const [data, setData] = useState([]);
  const [dataChuaDanhGia, setDataChuaDanhGia] = useState([]);

  const [selectedNam, setSelectedNam] = useState();
  const [selectedThang, setSelectedThang] = useState();

  const [selectedIdPhong, setSelectedIdPhong] = useState();

  const [loaiData, setLoaiData] = useState([]);
  const componentRef = useRef();
  const [unload, setUnLoad] = useState(0);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1; // Lưu ý tháng trong JavaScript bắt đầu từ 0, nên cần phải cộng thêm 1
  const year = today.getFullYear();

  useEffect(() => {
    //getData();
    setSelectedIdPhong(sessionStorage.getItem("id_phongkhoa"));
  }, []);

  const getData = (id_dv) => {
    axios
      .get(`${soTuTheoDoiAPI}/tonghop/${selectedNam}/${selectedThang}/${id_dv}`)
      .then((result) => {
        setData(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching Tổng Hợp Đánh giá data: ${error}`);
      });
  };

  const getDataChuaDanhGia = (id_dv) => {
    axios
      .get(
        `${soTuTheoDoiAPI}/chuadanhgia/${selectedNam}/${selectedThang}/${id_dv}`
      )
      .then((result) => {
        setDataChuaDanhGia(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching Chưa Đánh giá data: ${error}`);
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row className="align-items-center mb-3">
          {/* <Col xs={12} md={1}>
            <label>Năm:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedNam}
              onChange={(e) => {
                setSelectedNam(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn năm</option>
              {(() => {
                const options = [];
                for (let year = 2023; year <= 2040; year++) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          {/* <Col xs={12} md={1}>
            <label>Tháng:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedThang}
              onChange={(e) => {
                setSelectedThang(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn tháng</option>
              {(() => {
                const options = [];
                for (let thang = 1; thang <= 12; thang++) {
                  options.push(
                    <option key={thang} value={thang}>
                      {thang}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={1}>
            {/* <Button variant="outline-primary" onClick={() => getData()}> */}
            <Button
              variant="outline-primary"
              onClick={() => {
                if (selectedNam && selectedThang) {
                  getData(sessionStorage.getItem("id_dv"));
                  getDataChuaDanhGia(sessionStorage.getItem("id_dv"));
                  setUnLoad(1);
                }
              }}
            >
              <b>Xem</b>
            </Button>
          </Col>
          <Col xs={12} md={3}>
            {/* <Button variant="outline-primary" onClick={() => getData()}> */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="outline-info"
                  hidden={unload === 0 ? true : false}
                >
                  <b>
                    In danh sách chưa đánh giá <Icons.FaPrint />{" "}
                  </b>
                </Button>
              )}
              content={() => componentRef.current} // sử dụng biến ref
              bodyClass="print-body"
            />
          </Col>
        </Row>
      </Container>

      <Container style={{ width: "80%" }} hidden={unload === 0 ? true : false}>
        <p align="center" className="title">
          <b>BẢNG TỔNG HỢP ĐÁNH GIÁ CỦA ĐẢNG VIÊN - VIÊN CHỨC</b>
          <br />
          Tháng {selectedThang} - Năm {selectedNam}
        </p>
        <Table striped bordered hover className="responsive-table">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên Chi Bộ</th>
              <th>Số lượng Đã đánh giá</th>
              <th>Số lượng chưa đánh giá</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <tr key={index}>
                    <td> {index + 1} </td>
                    <td className="custom-text-left"> {item.tenPhongKhoa} </td>
                    <td> {item.daDanhGia} </td>
                    <td> {item.chuaDanhGia} </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>Không có dữ liệu ...</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>

      <Container style={{ width: "80%" }} hidden={true}>
        <Table className="print" border={0} ref={componentRef}>
          <tr>
            <td align="left">
              ĐẢNG BỘ {sessionStorage.getItem("ten_dv").toLocaleUpperCase()}
            </td>
            <td align="right">
              <u>
                <b>ĐẢNG CỘNG SẢN VIỆT NAM</b>
              </u>
            </td>
          </tr>
          <tr>
            <td align="left"></td>

            <td align="right">
              Kiên Giang, ngày {day}. tháng {month}. năm {year}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="title">
              <span className="text">
                {"DANH SÁCH ĐẢNG VIÊN - VIÊN CHỨC CHƯA ĐÁNH GIÁ\n Tháng "}{" "}
                {selectedThang} {"- năm "} {selectedNam}
              </span>
            </td>
          </tr>
          <tr>
            <td align="center" colSpan={2} height={35}>
              <hr width={150}></hr>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Table border={1} className="content">
                <tr className="thead">
                  <td>STT</td>
                  <td>Họ và tên</td>
                  <td>Tên Chi Bộ</td>
                  <td>Ghi chú</td>
                </tr>
                {/*----------------------Load dữ liệu------------------*/}
                <tbody>
                  {dataChuaDanhGia && dataChuaDanhGia.length > 0 ? (
                    dataChuaDanhGia.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td> {index + 1} </td>
                          <td className="custom-text-left"> {item.hoTen} </td>
                          <td align="center">{item.tenPhongKhoa}</td>
                          <td align="center"> </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={4}> Không có dữ liệu ...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>T/M CHI ỦY/CHI BỘ</b>
            </td>
          </tr>
        </Table>
      </Container>
    </Fragment>
  );
};

export default API_TongHopDanhGia;
