import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import { loginAPI } from "../../services/API_Config";
import ReCAPTCHA from "react-google-recaptcha"; // CAPTCHA


const API_QuenMatKhau = () => {
  // Quên đổi mật khẩu
  const [selectedUser, setSelectedUser] = useState("");
  const [editEmail, setEditEmail] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null); // CAPTCHA

  useEffect(() => {
    setSelectedUser(sessionStorage.getItem("taikhoan"));
  }, []);

  const navigate = useNavigate(); // Sử dụng useNavigate
  const navigateToLogin = (path) => {
    navigate(path);
  };

  const handleUpdateMatKhau = (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error("Vui lòng xác nhận bạn không phải là robot.");
      return;
    }
    
    const toastId = toast.info("Vui lòng chờ xử lý..."); // Lưu ID của thông báo
    const url = `${loginAPI}/ForgetPassword/${editEmail}`;
    const data = {
      email: editEmail,
    };

    axios
      .get(url, data)
      .then((result) => {
        if (result.status === 200) {
          toast.dismiss(toastId); // Ẩn thông báo "Vui lòng chờ xử lý..."
          navigateToLogin("/"); // Điều hướng về trang đăng nhập
          toast.success("Đã gửi lại mật khẩu đến email của bạn!");
          clear();
        }
      })
      .catch((error) => {
        toast.error("Lỗi email " + error.message);
      });
  };

  const clear = () => {
    setEditEmail("");
  };

  return (
    <>
      <Container>
        <Form onSubmit={handleUpdateMatKhau}>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <label htmlFor="quenMatKhau">QUÊN MẬT KHẨU</label>
            </Col>
          </Row>

          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <input
                type="email"
                className="form-control"
                placeholder="Nhập Email"
                required="required"
                value={editEmail}
                onChange={(e) => {
                  setEditEmail(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <ReCAPTCHA
                className="captcha"
                sitekey="6LfV_RopAAAAAJZfsx9GL9BvSOcNGVv0UD4WA6gV"
                hl="vi" // Thêm thuộc tính hl để chỉ định ngôn ngữ là tiếng Việt
                onChange={(value) => setCaptchaValue(value)}
              />
            </Col>
          </Row>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md="auto">
              <Button
                className="w-100 mb-2"
                variant="outline-primary"
                type="submit"
              >
                Gửi yêu cầu
              </Button>
            </Col>
            <Col xs={12} md="auto">
              <Button
                className="w-100 mb-2"
                variant="outline-primary"
                type="button"
                onClick={() => navigateToLogin("/")} // Điều hướng về trang đăng nhập
              >
                Trở về đăng nhập
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default API_QuenMatKhau;
