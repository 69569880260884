import React from "react";
import "../Styles/StyleComponents.scss";
import API_DonViSuper from "./API_DonViSuper";

function DonViSuper() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_DonViSuper />
    </div>
  );
}

export default DonViSuper;
