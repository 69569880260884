import React, { useState, useEffect, Fragment } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link } from "react-router-dom";
import "./Login.scss";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import logoDang from "../Images/quochuy.png";
import logoTruong from "../Images/logotruong.png";
import ReCAPTCHA from "react-google-recaptcha"; // CAPTCHA

import { loginAPI } from "../../services/API_Config";

// const Login = ({ showComponent }) => {
const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null); // CAPTCHA

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      console.log("Đã đăng nhập");
      setTimeout(() => navigate("/Home"), 10);
    }
  }, []);

  const loginAPICheck = (username, password) => {
    // Check captcha here
    if (!captchaValue) {
      toast.error("Vui lòng xác nhận bạn không phải là robot.");
      return;
    }
    // const url = "https://localhost:7023/api/Login";
    return axios.post(loginAPI, { username, password }).catch((error) => {
      // console.log(error.response.status);
      if (error.response.status === 404) {
        toast.error("Tên đăng nhập hoặc mật khẩu không đúng", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });        
        //  navigate("/Login");
      }
    });
  };

  const handleLogin = async () => {
    if (!username || !password) {
      toast.error("Vui lòng nhập tên đăng nhập và mật khẩu hợp lệ!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }
    let res = await loginAPICheck(username, password);
    if (res && res.data.token) {
      localStorage.setItem("token", res.data.token.accessToken);
      //Sửa lỗi đăng nhập; đăng ký session tài khoản trong trang login
      sessionStorage.setItem("taikhoan", username);
      navigate("/Home");
    }
  };

  // Ẩn, hiện password
  const [showPassword, setShowPassword] = useState(false); // Thêm state để theo dõi việc hiển thị mật khẩu

  // Xử lý xự kiện Enter
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      if (!username || !password) {
        toast.error("Vui lòng nhập tên đăng nhập và mật khẩu hợp lệ!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
      });
        return;
      }

      handleLogin();
    }
  };

  // Quên mật khẩu
  const handleForgotPassword = async () => {
    // Gọi đến trang API_QuenMatKhau.js
    navigate("/QuenMatKhau");
  };

  return (
    <Fragment>
      <div className="login-background">
        <Container>
          <div className="login-container-left">
            {/* <img src={logoTruong} alt="Logo" className="login-logo-truong" /> */}
            <span className="content">
              <strong>HỆ THỐNG QUẢN LÝ</strong>
              <br></br>
              <strong>ĐÁNH GIÁ ĐẢNG VIÊN, VIÊN CHỨC</strong>
              {/* <strong>TRƯỜNG CAO ĐẲNG KIÊN GIANG</strong> */}
            </span>
            <span className="footer">
              Website: kgc.edu.vn - Tel: (+84 0297) 3.863.530 - 3.872.086
              Hotline: 0916.769.269
              <br></br>
              Khoa Công nghệ thông tin
            </span>
          </div>
        </Container>

        <Container>
          <div className="login-container">
            <div className="login-content">
              <img src={logoDang} alt="Logo" className="login-logo" />
              <Row className="login-content">
                <label>Tài khoản:</label>
                <div className="form-input-container">
                  <input
                    type="text"
                    className="form-control login-input"
                    // placeholder="Tên đăng nhập"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onKeyUp={handleEnter}
                  />
                </div>
              </Row>
              <Row className="login-content">
                <label>Mật khẩu:</label>
                <div className="form-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control login-input"
                    // placeholder="Mật khẩu"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyUp={handleEnter}
                  />
                  <span
                    className="password-toggle-icon"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i>
                      {showPassword ? <Icons.FaEyeSlash /> : <Icons.FaEye />}
                    </i>
                  </span>
                </div>
              </Row>
              <Row className="login-content">
                {/* <div className="form-input-container"> */}
                <ReCAPTCHA
                  className="captcha"
                  sitekey="6LfV_RopAAAAAJZfsx9GL9BvSOcNGVv0UD4WA6gV"
                  hl="vi" // Thêm thuộc tính hl để chỉ định ngôn ngữ là tiếng Việt
                  onChange={(value) => setCaptchaValue(value)}
                />
                {/* </div> */}
              </Row>
              <button
                className="btn btn-outline-primary btn-login"
                onClick={handleLogin}
              >
                Đăng nhập
              </button>
            </div>
            <div className="text-center mt-2">
              <Link to="/QuenMatKhau" className="text-decoration-none">
                Quên mật khẩu?
              </Link>
              {/* <a
                href="#"
                className="text-decoration-none"
                onClick={handleForgotPassword}
              >
                Quên mật khẩu?
              </a> */}
            </div>
            {/* <div className="text-center mt-2">
              <a href="#" className="text-decoration-none">
                Đăng nhập bằng Gmail
              </a>
            </div> */}
            {/* <div className="text-center mt-4">
              <Link to="/UngHoUngDung" className="text-decoration-none">
                Ủng hộ ứng dụng
              </Link>
            </div> */}
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default Login;
