import React, { useState, useEffect } from "react";
import "../Home/Home.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { quyenTaiKhoanAPI } from "../../services/API_Config";
import * as Icons from "react-icons/fa";
import { RiArrowDownDoubleLine } from "react-icons/ri";
import logoTruong from "../Images/logotruong.png";

const Sidebar = ({ showComponent }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleNav = () => {
    if (isSidebarOpen) {
      openNav();
    } else {
      closeNav();
    }
    setSidebarOpen(!isSidebarOpen);
  };
  const openNav = () => {
    document.getElementById("mySidebar").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
  };
  const closeNav = () => {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  };

  const [isSuperAdminSubMenuOpen, setSuperAdminSubMenuOpen] = useState(false); //Super
  const [isAdminSubMenuOpen, setAdminSubMenuOpen] = useState(false);
  const [isPartySubMenuOpen, setPartySubMenuOpen] = useState(false);
  const [isBranchSubMenuOpen, setBranchSubMenuOpen] = useState(false);
  const [isMemberSubMenuOpen, setMemberSubMenuOpen] = useState(false);

  // Super
  const toggleSuperAdminSubMenu = () => {
    setSuperAdminSubMenuOpen(!isSuperAdminSubMenuOpen);
  };
  //---
  const toggleAdminSubMenu = () => {
    setAdminSubMenuOpen(!isAdminSubMenuOpen);
  };
  const togglePartySubMenu = () => {
    setPartySubMenuOpen(!isPartySubMenuOpen);
  };
  const toggleBranchSubMenu = () => {
    setBranchSubMenuOpen(!isBranchSubMenuOpen);
  };
  const toggleMemberSubMenu = () => {
    setMemberSubMenuOpen(!isMemberSubMenuOpen);
  };

  /** Các component */
  // Super
  const toggleTaiKhoanSuper = () => {
    showComponent("TaiKhoanSuper");
  };
  const toggleDonViSuper = () => {
    showComponent("DonViSuper");
  };
  const togglePhongKhoaSuper = () => {
    showComponent("PhongKhoaSuper");
  };
  const toggleLoaiSuper = () => {
    showComponent("LoaiSuper");
  };
  const toggleNoiDungSuper = () => {
    showComponent("NoiDungSuper");
  };
  //---

  const toggleTaiKhoan = () => {
    showComponent("TaiKhoan");
  };
  const toggleDonVi = () => {
    showComponent("DonVi");
  };
  const togglePhongKhoa = () => {
    showComponent("PhongKhoa");
  };
  const toggleLoai = () => {
    showComponent("Loai");
  };
  const togglePhanQuyen = () => {
    showComponent("PhanQuyen");
  };
  const toggleNoiDung = () => {
    showComponent("NoiDung");
  };
  const toggleKhoaMoSo = () => {
    showComponent("KhoaMoSo");
  };
  const toggleSoTuTheoDoi = () => {
    showComponent("SoTuTheoDoi");
  };
  const toggleKiemDuyet = () => {
    showComponent("KiemDuyet");
  };
  const toggleThayDoiMatKhau = () => {
    showComponent("ThayDoiMatKhau");
  };
  const toggleThayDoiThongTin = () => {
    showComponent("ThayDoiThongTin");
  };
  const toggleInPhuLucChiBo = () => {
    showComponent("InPhuLucChiBo");
  };
  const toggleInPhuLucTheoChiBo = () => {
    showComponent("InPhuLucTheoChiBo");
  };
  const toggleInPhuLucDangVien = () => {
    showComponent("InPhuLucDangVien");
  };
  const toggleTongHopDanhGia = () => {
    showComponent("TongHopDanhGia");
  };

  /* Xử lý đăng xuất */
  const navigate = useNavigate();
  const handleLogout = () => {
    if (window.confirm("Bạn chắc muốn đăng xuất?")) {
      localStorage.removeItem("token");
      sessionStorage.clear();
      navigate("/");
    }
  };

  const [quyenSuperAdmin, setQuyenSuperAdmin] = useState(false); // Super
  const [quyenAdmin, setQuyenAdmin] = useState(false);
  const [quyenParty, setQuyenParty] = useState(false);
  const [quyenBranch, setQuyenBranch] = useState(false);
  const [quyenMember, setQuyenMember] = useState(false);

  // Kiểm tra quyền khi đăng nhập cho phép mở component tương ứng
  const [selectedUser, setSelectedUser] = useState();
  const checkUserRoles = async () => {
    if (selectedUser) {
      await axios
        .get(`${quyenTaiKhoanAPI}/roles/${selectedUser}`)
        .then((result) => {
          const quyenTaiKhoan = result.data;
          // Duyệt qua từng tài khoản trong mảng quyenTaiKhoan

          for (let i = 0; i < quyenTaiKhoan.length; i++) {
            const account = quyenTaiKhoan[i];
            console.log(account);

            // Kiểm tra và cập nhật trạng thái submenu dựa trên Id_Quyen của từng tài khoản
            // Super
            if (account.idQuyen === 1) {
              setQuyenSuperAdmin(true);
            }
            if (account.idQuyen === 2) {
              setQuyenAdmin(true);
            }
            if (account.idQuyen === 3) {
              setQuyenParty(true);
            }
            if (account.idQuyen === 4) {
              setQuyenBranch(true);
            }
            if (account.idQuyen === 5) {
              setQuyenMember(true);
            }
          }
          console.log(quyenSuperAdmin); // Super
          console.log(quyenAdmin);
          console.log(quyenParty);
          console.log(quyenBranch);
          console.log(quyenMember);
        })
        .catch((error) => {
          console.log(`Lỗi khi lấy quyền tài khoản: ${error}`);
        });
    }
  };

  useEffect(() => {
    setSelectedUser(sessionStorage.getItem("taikhoan"));
    checkUserRoles();
  }, [selectedUser]);

  // Xử lý nút tên tài khoản
  const [isTaiKhoanSubMenuOpen, setTaiKhoanSubMenuOpen] = useState(false);

  const toggleTaiKhoanSubMenu = () => {
    setTaiKhoanSubMenuOpen(!isTaiKhoanSubMenuOpen);
  };

  // URL hướng dẫn
  const urlHuongDan =
    "https://drive.google.com/file/d/1269RGyRdCIlfkGn7wtlGUFlw5evtSkdc/view?usp=sharing";

  return (
    <>
      <div id="mySidebar" className="sidebar">
        {/* <a href="javascript:void(0)" className="closebtn" onClick={toggleNav}>
          ×
        </a> */}
        {/* Tên người dùng */}
        <div className={`dropdown ${isTaiKhoanSubMenuOpen ? "open" : ""}`}>
          <div className="dropdown">
            {/* <img src={logoTruong} alt="Logo" className="login-logo-truong" /> */}
            
            <a
              href="#"
              className="nav-item tai-khoan"
              // style={{ fontSize: 15 }}
              onClickCapture={toggleTaiKhoanSubMenu}
            >
              {/* <Icons.FaUserCircle /> {selectedUser} */}              
              {/* Sửa hiện họ tên tài khoản khi đăng nhập */}
              <Icons.FaUserCircle className="login-icon" />
              {sessionStorage.getItem("hoten")}
            </a>
            <div className="dropdown-content">
              <a href="#" onClick={toggleThayDoiThongTin}>
                <Icons.FaUserEdit /> Thay đổi thông tin
              </a>
              <a href="#" onClick={toggleThayDoiMatKhau}>
                <Icons.FaLockOpen /> Thay đổi mật khẩu
              </a>
              <a href="#" onClick={handleLogout}>
                <Icons.FaPowerOff /> Đăng xuất
              </a>
            </div>
          </div>
        </div>

        {/* <hr
          style={{
            width: "200px",
            margin: "0 auto",
            border: "1px solid #ffe600",
          }}
        /> */}

        <a href="/" className="nav-item">
          <Icons.FaHome /> Trang chủ
        </a>
        <a href="#" className="nav-item">
          <Icons.FaBell />
          Thông báo
        </a>
        <a href={urlHuongDan} target="_blank" className="nav-item">
          <Icons.FaQuestion />
          Hướng dẫn
        </a>

        {/* Mục Quản trị hệ thống*/}
        {quyenSuperAdmin && (
          <div className={`dropdown ${isSuperAdminSubMenuOpen ? "open" : ""}`}>
            <div className="dropdown">
              <a
                href="#"
                className="nav-item"
                onClick={toggleSuperAdminSubMenu}
              >
                <Icons.FaCog /> Quản trị cấp cao
              </a>
              {isSuperAdminSubMenuOpen && (
                <div className="dropdown-content">
                  <a href="#" onClick={toggleTaiKhoanSuper}>
                    Danh sách Tài khoản
                  </a>
                  <a href="#" onClick={toggleDonViSuper}>
                    Đơn vị
                  </a>
                  <a href="#" onClick={togglePhongKhoaSuper}>
                    Danh sách Chi bộ
                  </a>
                  <a href="#" onClick={togglePhanQuyen}>
                    Quyền hạn
                  </a>
                  <a href="#" onClick={toggleLoaiSuper}>
                    Loại biểu hiện
                  </a>
                  <a href="#" onClick={toggleNoiDungSuper}>
                    Nội dung biểu hiện
                  </a>
                  <a href="#">Khôi phục mật khẩu</a>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Mục Quản trị */}
        {quyenAdmin && (
          <div className={`dropdown ${isAdminSubMenuOpen ? "open" : ""}`}>
            <div className="dropdown">
              <a href="#" className="nav-item" onClick={toggleAdminSubMenu}>
                <Icons.FaCog /> Quản trị
              </a>
              {isAdminSubMenuOpen && (
                <div className="dropdown-content">
                  <a href="#" onClick={togglePhongKhoa}>
                    Danh sách Chi bộ
                  </a>
                  <a href="#" onClick={toggleTaiKhoan}>
                    Danh sách Tài khoản
                  </a>
                  {/* <a href="#" onClick={toggleDonVi}>
                    Đơn vị
              </a> 
                  
                  <a href="#" onClick={togglePhanQuyen}>
                    Quyền
                  </a>
                  <a href="#" onClick={toggleLoai}>
                    Loại
                  </a>
                  <a href="#" onClick={toggleNoiDung}>
                    Nội dung
                  </a>
                  <a href="#">Khôi phục mật khẩu</a>
              */}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Mục Đảng ủy */}
        {quyenParty && (
          <div className={`dropdown ${isPartySubMenuOpen ? "open" : ""}`}>
            <div className="dropdown">
              <a href="#" className="nav-item" onClick={togglePartySubMenu}>
                <Icons.FaBuilding />
                Đảng ủy
              </a>
              {isPartySubMenuOpen && (
                <div className="dropdown-content">
                  <a href="#" onClick={toggleTongHopDanhGia}>
                    Tổng hợp Đánh Giá
                  </a>
                  <a href="#" onClick={toggleInPhuLucTheoChiBo}>
                    In phụ lục theo chi bộ
                  </a>
                </div>
              )}
            </div>
          </div>
        )}

        {/* /* Mục Chi bộ */}
        {quyenBranch && (
          <div className={`dropdown ${isBranchSubMenuOpen ? "open" : ""}`}>
            <div className="dropdown">
              <a href="#" className="nav-item" onClick={toggleBranchSubMenu}>
                <Icons.FaCircle /> Chi bộ
              </a>
              {isBranchSubMenuOpen && (
                <div className="dropdown-content">
                  <a href="#" onClick={toggleKhoaMoSo}>
                    Mở khóa sổ
                  </a>
                  <a href="#" onClick={toggleKiemDuyet}>
                    Kiểm duyệt
                  </a>
                  <a href="#" onClick={toggleInPhuLucChiBo}>
                    In phụ lục chi bộ
                  </a>
                  {/* <a href="#">In phụ lục cá nhân</a> */}
                </div>
              )}
            </div>
          </div>
        )}
        {/* Mục Đảng viên - Giáo viên */}
        {quyenMember && (
          <div className={`dropdown ${isMemberSubMenuOpen ? "open" : ""}`}>
            <div className="dropdown">
              <a href="#" className="nav-item" onClick={toggleMemberSubMenu}>
                <Icons.FaUser /> Đảng viên - Giáo viên
              </a>
              {isMemberSubMenuOpen && (
                <div className="dropdown-content">
                  <a href="#" onClick={toggleSoTuTheoDoi}>
                    Sổ Tự theo dõi
                  </a>
                  <a href="#" onClick={toggleInPhuLucDangVien}>
                    In phụ lục
                  </a>
                  {/* <a href="#" onClick={toggleThayDoiThongTin}>
                    Thay đổi thông tin
                  </a>
                  <a href="#" onClick={toggleThayDoiMatKhau}>
                    Thay đổi mật khẩu
                  </a> */}
                </div>
              )}
            </div>
          </div>
        )}

        {/* Đăng xuất */}
        {/* <a href="" className="nav-item" onClick={handleLogout}>
          <Icons.FaPowerOff /> Đăng xuất
        </a> */}
      </div>
      <button className="openbtn" onClick={toggleNav}>
        ☰
      </button>
    </>
  );
};

export default Sidebar;
