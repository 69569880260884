import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { donViAPI } from "../../services/API_Config";

const API_DonVi = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [tenDonVi, setTenDonVi] = useState("");

  const [editIdDonVi, setEditIdDonVi] = useState("");
  const [editTenDonVi, setEditTenDonVi] = useState("");

  // Mảng chứa dữ liệu Đơn vị được lấy từ API. Ban đầu, nó sẽ được set là một mảng trống.
  const [data, setData] = useState([]);

  // Hook này được sử dụng để gọi hàm getData khi component được render lần đầu tiên
  useEffect(() => {
    getData(); //Gọi API
  }, []);

  /**
   * Hàm này gửi một yêu cầu HTTP GET đến API để lấy dữ liệu Đơn vị.
   * Dữ liệu nhận được sau đó được cập nhật vào biến trạng thái data.
   */
  const getData = () => {
    axios
      .get(donViAPI)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Khi người dùng muốn thêm một Đơn vị mới,
   * hàm này sẽ gửi yêu cầu HTTP POST để thêm dữ liệu mới.
   */
  const handleSave = () => {
    handleShowThem();
    if (!tenDonVi) {
      // toast.error("Vui lòng nhập tên đơn vị.");
      return;
    }
    const url = donViAPI;
    const data = {
      TenDonVi: tenDonVi,
    };

    axios
      .post(url, data)
      .then((result) => {
        if (result.status === 201) {
          toast.success("Đơn vị đã được thêm", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleCloseThem();
          getData();
          clear();
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  /**
   * Khi người dùng muốn sửa thông tin của một Đơn vị,
   * hàm này sẽ gửi yêu cầu HTTP GET để lấy thông tin chi tiết của Đơn vị dựa vào id được truyền vào.
   * Thông tin này sẽ được hiển thị trong modal để người dùng chỉnh sửa.
   */

  const handleEdit = (idDonVi) => {
    handleShow();

    axios
      .get(`${donViAPI}/${idDonVi}`)
      .then((result) => {
        setEditTenDonVi(result.data.tenDonVi);
        setEditIdDonVi(idDonVi);
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  /**
   * Khi người dùng hoàn tất việc chỉnh sửa thông tin của Đơn vị trong modal,
   * hàm này sẽ gửi yêu cầu HTTP PUT để cập nhật thông tin đã sửa.
   */
  const handleUpdate = () => {
    handleShow();
    const url = `${donViAPI}/${editIdDonVi}`;
    const data = {
      tenDonVi: editTenDonVi,
      idDonVi: editIdDonVi,
    };

    axios
      .put(url, data)
      .then((result) => {
        if (result.status === 200) {
          toast.success("Đơn vị đã được Cập nhật", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleClose(); //Đóng hộp cập nhật
          getData();
          clear();
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  /**
   * Khi người dùng muốn xóa tạm một Đơn vị (quản trị thường),
   * hàm này sẽ gửi yêu cầu HTTP PUT dựa vào id của Đơn vị.
   * Trước khi xóa, một hộp thoại xác nhận sẽ được hiển thị để người dùng xác nhận.
   */
  const handleDelete = (idDonVi) => {
    if (window.confirm("Bạn chắc chắn muốn xóa?") === true) {
      const url = `${donViAPI}/${idDonVi}`;

      // Lấy dữ liệu Đơn vị gốc từ state data dựa vào idDonVi
      const donViToDelete = data.find((item) => item.idDonVi === idDonVi);

      if (donViToDelete) {
        // Thực hiện cập nhật trạng thái xóa trong dữ liệu Đơn vị gốc
        donViToDelete.xoa = true;

        // Gửi yêu cầu cập nhật lên server
        axios
          .put(url, donViToDelete)
          .then((result) => {
            if (result.status === 200) {
              //success
              toast.success("Đơn vị đã được đánh dấu xóa", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
              getData();
            }
          })
          .catch((error) => {
            toast.error(error, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      }
    }
  };
  /**
   * Hàm này dùng để xóa thật sự xuống database
   */
  // const handleDelete = (idDonVi) => {
  //   if (window.confirm("Bạn chắc chắn muốn xóa?") === true) {
  //     axios
  //       .delete(`https://localhost:7023/api/DonVi/${idDonVi}`)
  //       .then((result) => {
  //         if (result.status === 200) {
  //           //success
  //           toast.success("Đơn vị đã được xóa");
  //           getData();
  //         }
  //       })
  //       .catch((error) => {
  //         toast.error(error);
  //       });
  //   }
  // };
  /**
   * Hàm này dùng để xóa rỗng các trường dữ liệu nhập liệu và các biến trạng thái liên quan sau khi hoàn tất thêm hoặc sửa.
   */
  const clear = () => {
    setTenDonVi("");
    editTenDonVi("");
    setEditIdDonVi(0);
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Modal show={showThem} onHide={handleCloseThem}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm đơn vị</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập đơn vị vào"
                  value={tenDonVi}
                  onChange={(e) => setTenDonVi(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThem}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!tenDonVi} // Vô hiệu hóa nếu tenDonVi rỗng
          >
            Thêm đơn vị
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row className="align-items-center">
          {/* <Col xs={12} md={3}>
            <input
              type="text"
              className="form-control"
              placeholder="Nhập tên vào"
              value={tenDonVi}
              onChange={(e) => setTenDonVi(e.target.value)}
            />
          </Col> */}
          <Col xs={12} md={2}>
            <button
              className="btn btn-outline-primary btn-block"
              onClick={() => handleSave()}
              // disabled={!tenDonVi}
            >
              Thêm đơn vị
            </button>
          </Col>
        </Row>
      </Container>
      <br />
      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>ID</th>
            <th>Tên đơn vị</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {item.idDonVi} </td>
                  <td className="custom-text-left"> {item.tenDonVi} </td>
                  <td colSpan={2}>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => handleEdit(item.idDonVi)}
                    >
                      Sửa{" "}
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(item.idDonVi)}
                    >
                      Xóa
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa đơn vị</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập đơn vị vào"
                  value={editTenDonVi}
                  onChange={(e) => setEditTenDonVi(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_DonVi;
