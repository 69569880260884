// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.scss";
// import App from "./containers/Home/App";
// import reportWebVitals from "./reportWebVitals";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Mới có kiểm tra phiên bản để load lại

import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./containers/Home/App";
import reportWebVitals from "./reportWebVitals";

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
};

// Kiểm tra phiên bản và tải lại trang nếu cần thiết
const checkAndReload = () => {
  // Lấy giá trị phiên bản từ biến môi trường hoặc một nguồn dữ liệu khác
  const currentVersion = process.env.REACT_APP_VERSION || "default";

  // Kiểm tra phiên bản đã lưu trữ trong localStorage
  const storedVersion = localStorage.getItem("appVersion");

  if (storedVersion !== currentVersion) {
    // Nếu phiên bản thay đổi, tải lại trang
    localStorage.setItem("appVersion", currentVersion);
    window.location.reload(true); // True để tải lại trang mà không sử dụng bộ nhớ cache
  } else {
    // Nếu phiên bản không thay đổi, hiển thị ứng dụng
    renderApp();
  }
};

// Gọi hàm kiểm tra và tải lại khi ứng dụng bắt đầu
checkAndReload();
// you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
