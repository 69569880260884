import React, { useState, useEffect, Fragment, useRef } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import { soTuTheoDoiAPI } from "../../services/API_Config";
import { loaiAPI, noiDungAPI, phongKhoaAPI } from "../../services/API_Config";
import ReactToPrint from "react-to-print";

const API_InPhuLucTheoChiBo = () => {
  const [data, setData] = useState([]);
  const [selectedNam, setSelectedNam] = useState();
  const [selectedThang, setSelectedThang] = useState();
  const [selectedPhuLuc, setSelectedPhuLuc] = useState();
  const [selectedIdDV, setSelectedIdDV] = useState();
  const [selectedChiBo, setSelectedChiBo] = useState();
  const [selectedIdChiBo, setSelectedIdChiBo] = useState();
  const [selectedTenChiBo, setSelectedTenChiBo] = useState();

  const [loaiData, setLoaiData] = useState([]);
  const [dsChiBoData, setDsChiBoData] = useState([]);
  const componentRef = useRef();
  const [unload, setUnLoad] = useState(0);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1; // Lưu ý tháng trong JavaScript bắt đầu từ 0, nên cần phải cộng thêm 1
  const year = today.getFullYear();

  useEffect(() => {
    //getData();

    getLoai();
    getChiBo(sessionStorage.getItem("id_dv"));
  }, []);

  const getData = (id_phong) => {
    axios
      .get(
        `${soTuTheoDoiAPI}/inphulucchibo/${selectedNam}/${selectedThang}/${selectedPhuLuc}/${id_phong}`
      )
      .then((result) => {
        setData(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching In Phụ Lục Chi Bộ data: ${error}`);
      });
  };

  const getLoai = async () => {
    try {
      const result = await axios.get(loaiAPI);
      setLoaiData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getChiBo = async (id_dv) => {
    try {
      const result = await axios.get(`${phongKhoaAPI}/dschibo/${id_dv}`);
      setDsChiBoData(result.data);
      console.log(result.data);
    } catch (error) {
      console.log(error + `${phongKhoaAPI}/dschibo/${id_dv}`);
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row className="align-items-center mb-3">
          {/* <Col xs={12} md={1}>
            <label>Năm:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedNam}
              onChange={(e) => {
                setSelectedNam(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn năm</option>
              {(() => {
                const options = [];
                for (let year = 2023; year <= 2040; year++) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          {/* <Col xs={12} md={1}>
            <label>Tháng:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedThang}
              onChange={(e) => {
                setSelectedThang(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn tháng</option>
              {(() => {
                const options = [];
                for (let thang = 1; thang <= 12; thang++) {
                  options.push(
                    <option key={thang} value={thang}>
                      {thang}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          {/* <Col xs={12} md={1}>
            <label>Phụ lục:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedPhuLuc}
              onChange={(e) => {
                setSelectedPhuLuc(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn Phụ lục</option>
              {(() => {
                const options = [];
                loaiData.map((loai) => {
                  options.push(
                    <option key={loai.idLoai} value={loai.idLoai}>
                      {loai.phuLuc}
                    </option>
                  );
                });
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={selectedChiBo}
              onChange={(e) => {
                setSelectedChiBo(e.target.value);
                const [id, ten] = e.target.value.split("-");
                setSelectedIdChiBo(id);
                setSelectedTenChiBo(ten);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn Chi bộ</option>
              {(() => {
                const options = [];
                dsChiBoData.map((chibo) => {
                  options.push(
                    <option
                      key={chibo.idPhongKhoa}
                      value={`${chibo.idPhongKhoa}-${chibo.tenPhongKhoa}`}
                    >
                      {chibo.tenPhongKhoa}
                    </option>
                  );
                });
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={2}>
            {/* <Button variant="outline-primary" onClick={() => getData()}> */}
            <Button
              variant="outline-primary"
              onClick={() => {
                if (
                  selectedNam &&
                  selectedThang &&
                  selectedPhuLuc &&
                  selectedChiBo
                ) {
                  getData(selectedIdChiBo);
                  setUnLoad(1);
                }
              }}
              style={{
                marginRight: "5px",
              }} /* Điều chỉnh khoảng cách bên phải */
            >
              <b>Xem</b>
            </Button>

            {/* <Button variant="outline-primary" onClick={() => getData()}> */}
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="outline-info"
                  hidden={unload === 0 ? true : false}
                >
                  <b>
                    In <Icons.FaPrint />{" "}
                  </b>
                </Button>
              )}
              content={() => componentRef.current} // sử dụng biến ref
              bodyClass="print-body"
            />
          </Col>
        </Row>
      </Container>

      <Container style={{ width: "80%" }} hidden={unload === 0 ? true : false}>
        <Table className="print" border={0} ref={componentRef}>
          <tr>
            <td align="left">
              ĐẢNG BỘ {sessionStorage.getItem("ten_dv").toLocaleUpperCase()}
            </td>
            <td align="right">
              <u>
                <b>ĐẢNG CỘNG SẢN VIỆT NAM</b>
              </u>
            </td>
          </tr>
          <tr>
            <td align="left">
              <b>
                CHI BỘ{" "}
                {selectedTenChiBo
                  ? selectedTenChiBo.toLocaleUpperCase()
                  : "...."}
              </b>
            </td>

            <td align="right">
              Kiên Giang, ngày {day} tháng {month} năm {year}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="title">
              <span className="text">
                {selectedPhuLuc === "1"
                  ? `BẢNG THEO DÕI, TỔNG HỢP
                  NHẬN DIỆN, ĐÁNH GIÁ CÁC BIỂU HIỆN SUY THOÁI VỀ TƯ TƯỞNG
                  CHÍNH TRỊ, ĐẠO ĐỨC, LỐI SỐNG; NHỮNG BIỂU HIỆN "TỰ DIỄN BIẾN",
                  "TỰ CHUYỂN HÓA" CỦA CÁN BỘ, ĐẢNG VIÊN
                  Tháng ${selectedThang} - Năm ${selectedNam}`
                  : selectedPhuLuc === "2"
                  ? `BẢNG THEO DÕI\n ĐÁNH GIÁ VIỆC CHẤP HÀNH QUY ĐỊNH NHỮNG ĐIỀU \nĐẢNG VIÊN, CÁN BỘ, CÔNG CHỨC, VIÊN CHỨC \nKHÔNG ĐƯỢC LÀM \nTháng ${selectedThang} - Năm ${selectedNam}`
                  : selectedPhuLuc === "3"
                  ? `BẢNG THEO DÕI, ĐÁNH GIÁ \nTHỰC HIỆN VIỆC NÊU GƯƠNG CỦA CÁN BỘ, ĐẢNG VIÊN \nTháng ${selectedThang} - Năm ${selectedNam}`
                  : ""}
              </span>
            </td>
          </tr>
          <tr>
            <td align="center" colSpan={2} height={35}>
              <hr width={150}></hr>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Table border={1} className="content">
                <tr className="thead">
                  <td rowspan="2">STT</td>
                  <td rowspan="2" width={200}>
                    Họ và tên
                  </td>
                  <td rowspan="2">
                    <span className="text">
                      {selectedPhuLuc === "1"
                        ? "Không có \n biểu hiện"
                        : selectedPhuLuc === "2"
                        ? "Không có vi phạm"
                        : selectedPhuLuc === "3"
                        ? "Đã thực hiện tốt các nội dung"
                        : ""}
                    </span>
                  </td>
                  <td colspan="3">Có biểu hiện</td>
                  <td rowspan="2">Ghi chú</td>
                </tr>
                <tr className="thead">
                  <td>
                    {selectedPhuLuc === "1"
                      ? "Nội dung biểu hiện"
                      : selectedPhuLuc === "2"
                      ? "Nội dung vi phạm"
                      : selectedPhuLuc === "3"
                      ? "Nội dung vi phạm"
                      : ""}
                  </td>
                  <td>Đã khắc phục</td>
                  <td>Chưa khắc phục</td>
                </tr>

                {/*----------------------Load dữ liệu------------------*/}
                <tbody>
                  {data && data.length > 0 ? (
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td> {index + 1} </td>
                          <td className="custom-text-left"> {item.hoTen} </td>
                          <td align="center">
                            {item.trangThai != null && item.trangThai === false
                              ? "X"
                              : ""}
                          </td>
                          <td className="custom-text-left">
                            {item.tenNoiDung}{" "}
                          </td>
                          <td align="center">
                            {" "}
                            {item.tinhTrangKhacPhuc === true ? "X" : ""}
                          </td>
                          <td align="center">
                            {" "}
                            {item.tinhTrangKhacPhuc === null ||
                            item.tinhTrangKhacPhuc !== false
                              ? ""
                              : "X"}
                          </td>
                          <td align="center"> </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>Loading ...</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <b>T/M CHI ỦY/CHI BỘ</b>
            </td>
          </tr>
        </Table>
      </Container>
    </Fragment>
  );
};

export default API_InPhuLucTheoChiBo;
