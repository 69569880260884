import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Form,
  Row,
  Col,
  Button,
  FloatingLabel,
  Container,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { taiKhoanAPI } from "../../services/API_Config";

const API_ThayDoiThongTin = () => {
  // Thay đổi mật khẩu
  const [selectedUser, setSelectedUser] = useState(""); // ID người dùng

  // Thay đổi thông tin
  const [editingTaiKhoan, setEditingTaiKhoan] = useState("");
  const [editingHoTen, setEditingHoTen] = useState("");
  const [editingMatKhau, setEditingMatKhau] = useState("");
  const [editingEmail, setEditingEmail] = useState("");
  const [editingSoDT, setEditingSoDT] = useState("");
  const [selectedPhongKhoaForTaiKhoan, setSelectedPhongKhoaForTaiKhoan] =
    useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    setSelectedUser(sessionStorage.getItem("taikhoan"));
    handleEditThongTin();
  }, []);

  const getData = () => {
    axios
      .get(`${taiKhoanAPI}/${sessionStorage.getItem("taikhoan")}`)
      .then((result) => {
        setData(result.data);
        console.log(result.data);
        console.log(`${taiKhoanAPI}/${sessionStorage.getItem("taikhoan")}`);
      })

      .catch((error) => {
        console.log(`Error fetching Tai Khoan data: ${error}`);
      });
  };

  const handleEditThongTin = () => {
    clear();
    axios
      .get(`${taiKhoanAPI}/${sessionStorage.getItem("taikhoan")}`)
      .then((result) => {
        setEditingTaiKhoan(result.data.taiKhoan);
        setEditingHoTen(result.data.hoTen);
        setEditingMatKhau(result.data.matKhau);
        setEditingEmail(result.data.email);
        setEditingSoDT(result.data.soDt);
        setSelectedPhongKhoaForTaiKhoan(result.data.idPhongKhoa);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handUpdateThongTin = async () => {
    try {
      const url = `${taiKhoanAPI}/${editingTaiKhoan}`;
      const data = {
        TaiKhoan: editingTaiKhoan,
        HoTen: editingHoTen,
        MatKhau: editingMatKhau,
        Email: editingEmail,
        SoDt: editingSoDT,
        IdPhongKhoa: selectedPhongKhoaForTaiKhoan,
      };

      const result = await axios.put(url, data);
      if (result.status === 200) {
        toast.success("Thông tin đã được cập nhật");
        clear();
      }
    } catch (error) {
      toast.error(error.message); // Sử dụng error.message để hiển thị thông báo lỗi cụ thể
    }
  };

  const clear = () => {
    setEditingHoTen("");
    setEditingEmail("");
    setEditingSoDT("");
  };

  return (
    <>
      <Container>
        <Form onSubmit={handUpdateThongTin}>
          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <label htmlFor="thongTin">THAY ĐỔI THÔNG TIN</label>
            </Col>
          </Row>

          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <input
                type="text"
                placeholder="Nhập họ tên"
                className="form-control"
                value={editingHoTen}
                onChange={(e) => setEditingHoTen(e.target.value)}
              />
            </Col>
          </Row>

          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <input
                type="email"
                placeholder="Nhập email"
                className="form-control"
                value={editingEmail}
                onChange={(e) => setEditingEmail(e.target.value)}
              />
            </Col>
          </Row>

          <Row className="mb-3" style={{ justifyContent: "center" }}>
            <Col xs={12} md={3}>
              <input
                type="number"
                placeholder="Nhập số điện thoại"
                className="form-control"
                value={editingSoDT}
                onChange={(e) => setEditingSoDT(e.target.value)}
              />
            </Col>
          </Row>

          {/* <FloatingLabel
            controlId="floatingInput"
            label="Họ tên:"
            className="mb-3"
          >
            <Form.Control
              type="text"
              placeholder="Nhập họ tên"
              value={editingHoTen}
              onChange={(e) => setEditingHoTen(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Email:"
            className="mb-3"
            style={{ justifyContent: "center" }}
          >
            <Form.Control
              type="email"
              placeholder="Nhập email"
              value={editingEmail}
              onChange={(e) => setEditingEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingInput"
            label="Số điện thoại:"
            className="mb-3"
            style={{ justifyContent: "center" }}
          >
            <Form.Control
              type="number"
              placeholder="Nhập số điện thoại"
              value={editingSoDT}
              onChange={(e) => setEditingSoDT(e.target.value)}
            />
          </FloatingLabel> */}

          <Button variant="outline-primary" type="submit">
            Lưu thay đổi
          </Button>
        </Form>
      </Container>
    </>
  );
};

export default API_ThayDoiThongTin;
