import React, { useState, useEffect, Fragment } from "react";
import {
  Table,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Form,
} from "react-bootstrap";
import axios from "axios";
import Collapse from "react-bootstrap/Collapse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  soTuTheoDoiAPI,
  noiDungSoTuTheoDoiAPI,
} from "../../services/API_Config";

const API_KiemDuyet = () => {
  const [selectedNam, setSelectedNam] = useState();
  const [selectedThang, setSelectedThang] = useState();

  /** Lấy data */
  const [data, setData] = useState([]);
  const [noiDungDuyet, setNoiDungDuyet] = useState([]);

  useEffect(() => {
    // getData();
  }, []);

  const getData = (nam, thang, phong) => {
    axios
      .get(`${soTuTheoDoiAPI}/kiemduyet/${nam}/${thang}/${phong}`)
      .then((result) => {
        setData(result.data);
        //  console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching data: ${error}`);
      });
  };

  const getNoiDungDuyet = (nam, thang, phong) => {
    axios
      .get(`${soTuTheoDoiAPI}/noidungkiemduyet/${nam}/${thang}/${phong}`)
      .then((result) => {
        setNoiDungDuyet(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching data Nội dung kiểm duyệt: ${error}`);
      });
  };
  const handleClick = () => {
    if (selectedNam && selectedThang) {
      getData(
        selectedNam,
        selectedThang,
        sessionStorage.getItem("id_phongkhoa")
      );
      getNoiDungDuyet(
        selectedNam,
        selectedThang,
        sessionStorage.getItem("id_phongkhoa")
      );
    }
  };
  const handleSave = () => {
    let check = false;
    let username = sessionStorage.getItem("hoten");
    // console.log(taiKhoan);
    const checkbox = document.getElementsByName("duyet");
    for (let i = 0; i < checkbox.length; i++) {
      if (checkbox[i].checked === true) {
        const urlSoTTD = `${soTuTheoDoiAPI}/updateduyet/${checkbox[i].value}/${username}`;

        axios.put(urlSoTTD).then((result) => {
          return result;
        });

        console.log(checkbox[i].value);
        check = true;
      }
    }
    // console.log(check);
    if (check === true) {
      toast.success("Duyệt thành công", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      setTimeout(() => {
        getData(
          selectedNam,
          selectedThang,
          sessionStorage.getItem("id_phongkhoa")
        );
        getNoiDungDuyet(
          selectedNam,
          selectedThang,
          sessionStorage.getItem("id_phongkhoa")
        );
      }, 4000); // Sẽ gọi lại sau 4s (4000ms)
    } else {
      toast.error("Bạn phải chọn ít nhất người để duyệt", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const checkAll = () => {
    // Khai báo tham số
    var i;
    var checkboxes = document.getElementsByName("duyet");

    if (document.getElementById("cb_all").checked === true) {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
      }
    } else {
      for (i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
    }

    return true;
  };

  return (
    <Fragment>
      {/* Hiển thị */}

      <Container>
        <Row className="align-items-center mb-3">
          <Col xs={12} md={1}>
            <label>Năm:</label>
          </Col>
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedNam}
              onChange={(e) => {
                setSelectedNam(e.target.value);
              }}
            >
              <option value="">Chọn năm</option>
              {(() => {
                const options = [];
                for (let year = 2023; year <= 2040; year++) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={1}>
            <label>Tháng:</label>
          </Col>
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedThang}
              onChange={(e) => {
                setSelectedThang(e.target.value);
              }}
            >
              <option value="">Chọn tháng</option>
              {(() => {
                const options = [];
                for (let thang = 1; thang <= 12; thang++) {
                  options.push(
                    <option key={thang} value={thang}>
                      {thang}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={1}>
            {/* <Button variant="outline-primary" onClick={() => getData()}> */}
            <Button variant="outline-primary" onClick={() => handleClick()}>
              Xem
            </Button>
          </Col>
        </Row>
      </Container>
      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th rowSpan={2}>STT</th>
            <th rowSpan={2}>Họ tên</th>
            <th rowSpan={2}>Thời gian đánh giá</th>
            <th rowSpan={2}>Kiểm duyệt</th>
            <th colSpan={3}>Chi tiết Nội dung đánh giá</th>
            <th rowSpan={2}>
              <div className="form-group">
                <label className="form-check-label" htmlFor="cb_all">
                  Chọn Tất cả
                </label>
                <br />
                <input
                  type="checkbox"
                  className="form-check-input"
                  value={""}
                  onClick={() => checkAll()}
                  id="cb_all"
                />
              </div>
            </th>
          </tr>
          <tr>
            <th>
              Biểu hiện <br /> suy thoái
            </th>
            <th>
              Những điều <br /> không được làm
            </th>
            <th>
              Thực hiện <br /> việc nêu gương
            </th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => (
              <tr key={index}>
                <td> {index + 1} </td>
                <td className="custom-text-left"> {item.hoTen} </td>
                <td className="custom-text-left"> {item.thoiGianDanhGia} </td>
                <td className="custom-text-left"> {item.kiemDuyet} </td>

                {noiDungDuyet
                  .filter(
                    (itemnd) =>
                      itemnd.idSoTuTheoDoi === parseInt(item.idSoTuTheoDoi)
                  )
                  .map((itemnd, indexnd) => {
                    let li = [];
                    if (itemnd.idLoai === 1) {
                      // 82 Biểu hiện suy thoái
                      if (itemnd.trangThai === true) {
                        li.push(
                          <li>
                            Có biểu hiện
                            <br />
                          </li>
                        );
                        li.push(
                          <li>
                            {itemnd.tenNoiDung.slice(0, 30) + "..."}
                            <br />
                          </li>
                        );
                        li.push(
                          <li>
                            {itemnd.tinhTrangKhacPhuc
                              ? "Đã khắc phục"
                              : "Chưa khắc phục"}
                            <br />
                          </li>
                        );

                        return <td align="left">{li}</td>;
                      } else {
                        li.push(
                          <li>
                            Không biểu hiện
                            <br />
                          </li>
                        );
                        return <td align="left">{li}</td>;
                      }
                    }
                    if (itemnd.idLoai === 2) {
                      // Những điều không được làm
                      if (itemnd.trangThai === true) {
                        li.push(
                          <li>
                            Có vi phạm
                            <br />
                          </li>
                        );
                        li.push(
                          <li>
                            {itemnd.tenNoiDung.slice(0, 30) + "..."}
                            <br />
                          </li>
                        );
                        li.push(
                          <li>
                            {itemnd.tinhTrangKhacPhuc
                              ? "Đã khắc phục"
                              : "Chưa khắc phục"}
                            <br />
                          </li>
                        );
                        return <td align="left">{li}</td>;
                      } else {
                        li.push(
                          <li>
                            Không vi phạm
                            <br />
                          </li>
                        );
                        return <td align="left">{li}</td>;
                      }
                    }
                    if (itemnd.idLoai === 3) {
                      // Thực hiện việc nêu gương
                      if (itemnd.trangThai === true) {
                        li.push(
                          <li>
                            Có vi phạm
                            <br />
                          </li>
                        );
                        li.push(
                          <li>
                            {itemnd.tenNoiDung.slice(0, 30) + "..."}
                            <br />
                          </li>
                        );
                        li.push(
                          <li>
                            {itemnd.tinhTrangKhacPhuc
                              ? "Đã khắc phục"
                              : "Chưa khắc phục"}
                            <br />
                          </li>
                        );
                        return <td align="left">{li}</td>;
                      } else {
                        li.push(
                          <li>
                            Đã thực hiện tốt
                            <br />
                          </li>
                        );
                        return <td align="left">{li}</td>;
                      }
                    }
                  })}
                {noiDungDuyet.filter(
                  (itemnd) =>
                    itemnd.idSoTuTheoDoi === parseInt(item.idSoTuTheoDoi)
                ).length === 0 && (
                  <React.Fragment>
                    <td key={1}></td>
                    <td key={2}></td>
                    <td key={3}></td>
                  </React.Fragment>
                )}
                <td>
                  {((e) => {
                    const checkbox = [];
                    if (item.idSoTuTheoDoi != null) {
                      if (item.kiemDuyet !== null) {
                        checkbox.push(
                          <div>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="duyet"
                              value={`${item.idSoTuTheoDoi}`}
                              hidden={true}
                            />
                            Đã duyệt
                          </div>
                        );
                      } else {
                        checkbox.push(
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="duyet"
                            value={`${item.idSoTuTheoDoi}`}
                          />
                        );
                      }
                    }
                    return checkbox;
                  })()}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={8}>Loading ...</td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}>
              {data && data.length > 0 && (
                <button
                  className="btn btn-primary float-end"
                  onClick={() => {
                    handleSave();
                    handleClick();
                  }}
                >
                  Duyệt
                </button>
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      <div style={{ textAlign: "left" }}>
        <b>
          <u>Diễn giải:</u>
        </b>
        <ul>
          {noiDungDuyet
            .filter((itemnd) => itemnd.trangThai === true)
            .map((itemnd, indexnd) => (
              <li style={{ paddingTop: "10px" }} key={`li-${indexnd}`}>
                {itemnd.tenNoiDung}
                <br />
              </li>
            ))}
          {noiDungDuyet.filter((itemnd) => itemnd.trangThai === true).length ===
            0 && (
            <li style={{ paddingTop: "10px" }}>
              Không có
              <br />
            </li>
          )}
        </ul>
      </div>
    </Fragment>
  );
};

export default API_KiemDuyet;
