import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  taiKhoanAPI,
  phanQuyenAPI,
  phongKhoaAPI,
  quyenTaiKhoanAPI,
} from "../../services/API_Config";

const API_TaiKhoan = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [showPhanQuyen, setShowPhanQuyen] = useState(false);
  const handleClosePhanQuyen = () => setShowPhanQuyen(false);
  const handleShowPhanQuyen = () => setShowPhanQuyen(true);

  const [taiKhoan, setTaiKhoan] = useState("");
  const [hovaten, setHoTen] = useState("");
  // const [matKhau, setMatKhau] = useState("");
  const [email, setEmail] = useState("");
  const [soDT, setSoDT] = useState("");

  const [editingTaiKhoan, setEditingTaiKhoan] = useState("");
  const [editingHoTen, setEditingHoTen] = useState("");
  const [editingMatKhau, setEditingMatKhau] = useState("");
  const [editingEmail, setEditingEmail] = useState("");
  const [editingSoDT, setEditingSoDT] = useState("");
  const [selectedPhongKhoaForTaiKhoan, setSelectedPhongKhoaForTaiKhoan] = useState("");

  /** Lấy data */
  const [data, setData] = useState([]);
  const [dataQuyen, setDataQuyen] = useState([]);
  const [phongKhoaDataView, setPhongKhoaDataView] = useState({}); // Lấy data phòng khoa xử lý view
  const [phongKhoaData, setPhongKhoaData] = useState([]); // Lấy data phong khoa xử lý thêm, sửa
  const [phanQuyenData, setPhanQuyenData] = useState([]);
  const [quyenTaiKhoanData, setQuyenTaiKhoanData] = useState([]);

  /** Xử lý phân trang */
  // Biến trạng thái để theo dõi trang hiện tại và số lượng mục trên mỗi trang
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // Hàm để tính vị trí bắt đầu và kết thúc của danh sách hiện tại dựa trên currentPage và itemsPerPage
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // Nút điều hướng phân trang (trước, sau, số trang) và xử lý sự kiện
  const totalPages = Math.ceil(data.length / itemsPerPage);
  // const totalPages = data.length > 0 ? Math.ceil(data.length / itemsPerPage) : 1;
 
  // Chức năng tìm kiếm
  const [searchTerm, setSearchTerm] = useState("");
  const [search, setSearch] = useState(0);  

  // Số lượng trang tối đa muốn hiển thị (ví dụ: 5 trang)
  const maxPages = 5;

  // Tạo danh sách các trang cần hiển thị
  let pageNumbers = [];
  if (totalPages <= maxPages) {
    pageNumbers = [...Array(totalPages).keys()].map((number) => number + 1);
  } else {
    const halfMax = Math.floor(maxPages / 2);
    const firstPage = Math.max(currentPage - halfMax, 1);
    const lastPage = Math.min(currentPage + halfMax, totalPages);
    pageNumbers = [...Array(lastPage - firstPage + 1).keys()].map(
      (number) => firstPage + number
    );
  }

   //----------------------------------------------------------
   useEffect(() => {
    getData();
    getPhongKhoa();
    getPhanQuyen();
  }, []);

  // Hàm xử lý chuyển trang
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // Thêm các nút điều hướng trước và sau
  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Xử lý sửa quyền 
  const [idQuyenTaiKhoan, setIdQuyenTaiKhoan] = useState(""); 
  const [editingIdQuyen, setEditingIdQuyen] = useState(""); 
  // Lọc theo phòng khoa
  const [selectedPhongKhoaFilter, setSelectedPhongKhoaFilter] = useState("");
 
  const getData = () => {
    axios
      .get(`${taiKhoanAPI}/dstheodonvi/${sessionStorage.getItem("id_dv")}`) // Đảm bảo API đúng cho tài khoản
      .then((result) => {
        setData(result.data);
        // Yêu cầu thông tin phòng khoa cho mỗi bản ghi tài khoản; user tên biến
        result.data.forEach((user) => {
          axios
            .get(`${phongKhoaAPI}/${user.idPhongKhoa}`)
            .then((phongKhoaResult) => {
              setPhongKhoaDataView((prevData) => ({
                ...prevData,
                [user.taiKhoan]: phongKhoaResult.data,
              }));
              // console.log(phongKhoaResult);
            })
            .catch((error) => {
              console.log(`Error fetching Phong Khoa: ${error}`);
            });
        });
      })
      .catch((error) => {
        console.log(`Error fetching Tai Khoan data: ${error}`);
      });
  };

  const handleChangeSearch = (event) => {
    setSearchTerm(event.target.value);
    setSearch(0);
    console.log(searchTerm);
  };
  const handleSearch = (event) => {
    setSearch(1);
    console.log(searchTerm);
  };
  const getPhongKhoa = () => {
    axios
      .get(`${phongKhoaAPI}/dschibo/${sessionStorage.getItem("id_dv")}`)
      .then((result) => {
        setPhongKhoaData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPhanQuyen = () => {
    axios
      .get(phanQuyenAPI)
      .then((result) => {
        setPhanQuyenData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getQuyenTaiKhoan = async (user) => {
    axios
      .get(`${quyenTaiKhoanAPI}/roles/${user}`)
      .then((result) => {
        setQuyenTaiKhoanData(result.data);
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = async () => {
    if (
      !taiKhoan ||
      !hovaten ||
      !email ||
      !selectedPhongKhoaForTaiKhoan ||
      checkedItems.length === 0
    ) {
      toast.error("Vui lòng nhập đầy đủ thông tin.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    const url = taiKhoanAPI;

    const data = {
      TaiKhoan: taiKhoan,
      HoTen: hovaten,
      Email: email,
      MatKhau: "cntt@123",
      SoDt: soDT,
      IdPhongKhoa: selectedPhongKhoaForTaiKhoan,
    };

    console.log(data);
    axios
      .post(url, data)
      .then((result) => {
        if (result.data.success === false) {
          toast.error(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        if (result.status === 201) {
          // Thêm quyền vào bảng QuyenTaiKhoan
          const urlQuyenTaiKhoan = quyenTaiKhoanAPI;
          console.log("Chiều dài : " + checkedItems.length);
          for (let i = 0; i <= checkedItems.length; i++) {
            if (checkedItems[i] !== undefined) {
              console.log(checkedItems[i]);
              const dataQuyenTaiKhoan = {
                TaiKhoan: taiKhoan,
                IdQuyen: checkedItems[i],
              };
              axios
                .post(urlQuyenTaiKhoan, dataQuyenTaiKhoan)
                .then((resultQuyen) => {
                  return resultQuyen;
                });
            }
          }
          getPhongKhoa(); // không gọi thi thêm lần nữa ko được, vì phải lấy lên dể chọn
          getPhanQuyen(); // không gọi thi thêm lần nữa ko được
          getData();
          clear();
          toast.success("Tài khoản đã được thêm", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleCloseThem();
        }
      })
      .catch((error) => {
        toast.error("Lỗi " + error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const getIdQTK = async (user) => {
    const idQTK = getQuyenTaiKhoan(user);
    try {
      const response = await axios.get(`${quyenTaiKhoanAPI}/${idQTK}`);
      const idQuyenTaiKhoan = response.data.idQuyenTaiKhoan;
      setIdQuyenTaiKhoan(idQuyenTaiKhoan); //
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleEdit = (user) => {
    clear();
    handleShow();
    axios
      .get(`${taiKhoanAPI}/${user}`)
      .then((result) => {
        setEditingTaiKhoan(user);
        setEditingHoTen(result.data.hoTen);
        setEditingMatKhau(result.data.matKhau);
        setEditingEmail(result.data.email);
        setEditingSoDT(result.data.soDt);
        setSelectedPhongKhoaForTaiKhoan(result.data.idPhongKhoa);
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  ///Mở Modal Cập nhật quyền tài khoản
  const handleEditQuyen = (user, fullname) => {
    setHoTen(fullname);
    setTaiKhoan(user);
    axios
      .get(`${phanQuyenAPI}/editquyen/${user}`)
      .then((result) => {
        if (result.status === 200) {
          setDataQuyen(result.data);
          handleShowPhanQuyen();
        }
      })
      .catch((error) => {
        console.log(error.message);
        console.log(`${phanQuyenAPI}/editquyen/${user}`);
      });
  };
  ///Cập nhật quyền tài khoản
  const handleUpdateQuyen = async () => {
    let check = false;
    // console.log(taiKhoan);
    const checkbox = document.getElementsByName("role");
    // console.log(checkbox.length);
    const urldel = `${quyenTaiKhoanAPI}/deleteRole/${taiKhoan}`;
    const res = await axios
      .delete(urldel)
      .then((result) => {})
      .catch((error) => {
        console.log(error);
      });
    for (let i = 0; i < checkbox.length; i++) {
      if (checkbox[i].checked === true) {
        console.log(checkbox[i].value);
        // // Thêm quyền vào bảng QuyenTaiKhoan
        const urlQuyenTaiKhoan = quyenTaiKhoanAPI;
        const dataQuyenTaiKhoan = {
          TaiKhoan: taiKhoan,
          IdQuyen: checkbox[i].value,
        };
        console.log(dataQuyenTaiKhoan);
        axios.post(urlQuyenTaiKhoan, dataQuyenTaiKhoan).then((resultQuyen) => {
          return resultQuyen;
        });
      }
      check = true;
    }

    if (check === true) {
      toast.success("Cập nhật lại quyền thành công", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      handleClosePhanQuyen();
      clear();
    } else {
      toast.error("Bạn phải chọn ít nhất 1 quyền", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };
  const handleUpdate = async () => {
    try {
      const url = `${taiKhoanAPI}/${editingTaiKhoan}`;
      const data = {
        TaiKhoan: editingTaiKhoan,
        HoTen: editingHoTen,
        MatKhau: editingMatKhau,
        Email: editingEmail,
        SoDt: editingSoDT,
        IdPhongKhoa: selectedPhongKhoaForTaiKhoan,
      };

      const result = await axios.put(url, data);
      if (result.status === 200) {
        // Đóng modal, tải lại dữ liệu, và làm sạch trạng thái
        handleClose();
        getData();
        clear();
        toast.success("Tài khoản đã được cập nhật");
      }
    } catch (error) {
      console.error("Lỗi khi cập nhật tài khoản:", error); // Log lỗi để kiểm tra
      toast.error("Đã xảy ra lỗi khi cập nhật tài khoản", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleDelete = async (taiKhoan) => {
    const shouldDelete = window.confirm("Bạn chắc chắn muốn xóa?");

    if (!shouldDelete) {
      return;
    }
    const url = `${taiKhoanAPI}/${taiKhoan}`;
    try {
      const index = data.findIndex((item) => item.taiKhoan === taiKhoan);
      if (index !== -1) {
        const updatedTaiKhoan = { ...data[index], xoa: true };
        const response = await axios.put(url, updatedTaiKhoan);
        // kiem tra
        console.log(response); // Xử lý dữ liệu trả về nếu cần
        if (response.status === 200) {
          const updatedData = [...data];
          updatedData[index] = updatedTaiKhoan;
          setData(updatedData);
          getData();
          toast.success("Tài khoản đã được đánh dấu xóa", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      // kiem tra
      console.error("Lỗi từ server:", error);
      toast.error("Đã xảy ra lỗi khi xóa tài khoản", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  // kiểm tra và lấy giá trị checkbox
  const [checkedItems, setCheckedItems] = useState([]);

  const handleChangecheck = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      setCheckedItems([...checkedItems, value]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    }
  };

  const clear = () => {
    setTaiKhoan("");
    setHoTen("");

    setEmail("");
    setSoDT("");
    setEditingTaiKhoan("");
    setEditingHoTen("");
    setEditingMatKhau("");
    setEditingEmail("");
    setEditingSoDT("");
    setSelectedPhongKhoaForTaiKhoan("");
    setCheckedItems([]);
  };

  return (
    <Fragment>
      {/* Cửa sổ Thêm */}
      <Modal show={showThem} onHide={handleCloseThem}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm tài khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tài khoản"
                  value={taiKhoan}
                  onChange={(e) => setTaiKhoan(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Họ tên"
                  value={hovaten}
                  onChange={(e) => setHoTen(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Số điện thoại"
                  value={soDT}
                  onChange={(e) => setSoDT(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <select
                  className="form-select"
                  value={selectedPhongKhoaForTaiKhoan}
                  onChange={(e) =>
                    setSelectedPhongKhoaForTaiKhoan(e.target.value)
                  }
                >
                  <option value="">Chọn chi bộ</option>
                  {phongKhoaData.map((phongKhoa) => (
                    <option
                      key={phongKhoa.idPhongKhoa}
                      value={phongKhoa.idPhongKhoa}
                    >
                      {phongKhoa.tenPhongKhoa}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <fieldset>
                  <b>Cấp quyền người dùng:</b>

                  {(() => {
                    const checkbox = [];
                    for (let i = 1; i < phanQuyenData.length; i++) {
                      const itemQ = phanQuyenData[i];
                      checkbox.push(
                        <div className="form-check">
                          <label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={`${itemQ.idQuyen}`}
                              onChange={(e) => handleChangecheck(e)}
                            />
                            &nbsp; {itemQ.quyen}
                          </label>
                        </div>
                      );
                    }
                    return checkbox;
                  })()}
                </fieldset>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              clear();
              handleCloseThem();
            }}
          >
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSave()}
            disabled={
              !taiKhoan || !hovaten || !email || !selectedPhongKhoaForTaiKhoan
            }
          >
            Thêm tài khoản
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cửa sổ cập nhật phân quyền  */}
      <Modal show={showPhanQuyen} onHide={handleClosePhanQuyen}>
        <Modal.Header closeButton>
          <Modal.Title>Cập nhật quyền tài khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tài khoản"
                  value={taiKhoan}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Họ tên"
                  value={hovaten}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                {((e) => {
                  const checkbox = [];
                  for (let i = 1; i < dataQuyen.length; i++) {
                    const itemQ = dataQuyen[i];
                    checkbox.push(
                      <div className="form-check">
                        <label>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="role"
                            value={`${itemQ.idQuyen}`}
                            defaultChecked={itemQ.idQuyenTK !== null}
                          />
                          &nbsp; {itemQ.quyen}
                        </label>
                      </div>
                    );
                  }
                  return checkbox;
                })()}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              clear();
              handleClosePhanQuyen();
            }}
          >
            Đóng
          </Button>
          <Button variant="primary" onClick={() => handleUpdateQuyen()}>
            Lưu cập nhật
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <button
              className="btn btn-outline-primary w-100"
              onClick={() => {
                clear();
                setCheckedItems([]);
                handleShowThem();
              }}
            >
              Thêm tài khoản
            </button>
          </Col>
          <Col xs={0} md={3}></Col>
          <Col xs={0} md={3}></Col>
          <Col xs={12} md={3} className="d-flex justify-content-end">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Nhập họ tên cần tìm"
                value={searchTerm}
                onChange={(e) => {
                  handleChangeSearch(e);
                  setItemsPerPage(data.length);
                }}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  setSelectedPhongKhoaFilter("");
                  setItemsPerPage(data.length);
                  setSearch(1);
                  handleSearch(e);
                }}
              >
                <BsSearch />
              </button>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Hiển thị */}
      <Container>
        <Row className="mb-3">
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
              }}
              disabled={selectedPhongKhoaFilter === "" ? false : true}
            >
              <option value={10}>10 mục/trang</option>
              <option value={20}>20 mục/trang</option>
              <option value={50}>50 mục/trang</option>
              {/* Thêm tất cả để lọc toàn bô */}
              <option value={data.length}>Tất cả mục</option>
            </select>
          </Col>
          <Col xs={12} md={6}>
            <div className="pagination">
              <Button
                variant="outline-info"
                onClick={() => prevPage()}
                disabled={currentPage === 1}
              >
                Trang trước
              </Button>{" "}
              &nbsp;
              {pageNumbers.map((pageNumber) => (
                <Button
                  key={pageNumber}
                  variant={`outline-info ${
                    currentPage === pageNumber ? "active" : ""
                  }`}
                  onClick={() => paginate(pageNumber)}
                >
                  {pageNumber}
                </Button>
              ))}
              &nbsp;
              <Button
                variant="outline-info"
                onClick={() => nextPage()}
                disabled={indexOfLastItem >= data.length}
              >
                Trang sau
              </Button>
            </div>
          </Col>
          <Col xs={12} md={3}>
            <select
              className="form-select"
              value={selectedPhongKhoaFilter}
              onChange={(e) => {
                setSelectedPhongKhoaFilter(e.target.value);
                setItemsPerPage(data.length);
                setSearchTerm("");
              }}
            >
              <option value="">Chọn Chi bộ (Lọc)</option>
              {phongKhoaData.map((phongKhoa) => (
                <option
                  key={phongKhoa.idPhongKhoa}
                  value={phongKhoa.idPhongKhoa}
                >
                  {phongKhoa.tenPhongKhoa}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>Tài khoản</th>
            <th>Họ tên</th>
            {/* <th>Mật khẩu</th> */}
            <th>Email</th>
            <th>Số điện thoại</th>
            <th>Chi bộ</th>
            {/* <th>Quyền</th> */}
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            currentItems
              .filter((item) => {
                // Kiểm tra nếu selectedPhongKhoaFilter là trống hoặc khớp với idPhongKhoa của mục
                return (
                  selectedPhongKhoaFilter === "" ||
                  item.idPhongKhoa === parseInt(selectedPhongKhoaFilter)
                );
              })

              .filter((item) => {
                // Tìm kiếm theo tên
                const lowerCaseHoTen = item.hoTen
                  .toLowerCase()
                  .normalize("NFC");
                const lowerCaseSearchTerm = searchTerm
                  .toLowerCase()
                  .normalize("NFC");
                const includesResult =
                  lowerCaseHoTen.endsWith(lowerCaseSearchTerm);

                return searchTerm !== "" ? includesResult : searchTerm === "";
              })

              .map((item, index) => (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {item.taiKhoan} </td>
                  <td className="custom-text-left"> {item.hoTen} </td>
                  {/* <td className="custom-text-left"> {item.matKhau} </td> */}
                  <td className="custom-text-left"> {item.email} </td>
                  <td className="custom-text-left"> {item.soDt} </td>
                  <td className="custom-text-left">
                    {phongKhoaDataView[item.taiKhoan]?.tenPhongKhoa || ""}
                  </td>
                  <td colSpan={2}>
                    <button
                      className="btn btn-outline-info"
                      onClick={() => handleEditQuyen(item.taiKhoan, item.hoTen)}
                    >
                      Cấp quyền
                    </button>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        handleEdit(item.taiKhoan);
                      }}
                    >
                      Sửa{" "}
                    </button>
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(item.taiKhoan)}
                    >
                      Xóa
                    </button>
                  </td>
                </tr>
              ))
          ) : (
            <tr>
              <td colSpan={8}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Sửa */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa tài khoản</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {/* <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Tài khoản"
                  value={editingTaiKhoan}
                  onChange={(e) => setEditingTaiKhoan(e.target.value)}
                />
              </Col>
            </Row> */}
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Họ tên"
                  value={editingHoTen}
                  onChange={(e) => setEditingHoTen(e.target.value)}
                />
              </Col>
            </Row>
            {/* <Row className="mb-3">
              <Col>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Mật khẩu"
                  value={editingMatKhau}
                  onChange={(e) => setEditingMatKhau(e.target.value)}
                />
              </Col>
            </Row> */}
            <Row className="mb-3">
              <Col>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={editingEmail}
                  onChange={(e) => setEditingEmail(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Số điện thoại"
                  value={editingSoDT}
                  onChange={(e) => setEditingSoDT(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <select
                  className="form-select"
                  value={selectedPhongKhoaForTaiKhoan}
                  onChange={(e) =>
                    setSelectedPhongKhoaForTaiKhoan(e.target.value)
                  }
                >
                  <option value="">Chọn chi bộ</option>
                  {phongKhoaData.map((phongKhoa) => (
                    <option
                      key={phongKhoa.idPhongKhoa}
                      value={phongKhoa.idPhongKhoa}
                    >
                      {phongKhoa.tenPhongKhoa}
                    </option>
                  ))}
                </select>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_TaiKhoan;
