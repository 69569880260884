import React, { useState, useEffect, Fragment, useRef } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import { inPhuLucDangVienAPI } from "../../services/API_Config";
import { soTuTheoDoiAPI } from "../../services/API_Config";
import { loaiAPI, noiDungAPI } from "../../services/API_Config";
import ReactToPrint from "react-to-print";

const API_InPhuLucDangVien = () => {
  const [data, setData] = useState([]);
  const [selectedNam, setSelectedNam] = useState();
  const [selectedThang, setSelectedThang] = useState();
  const [selectedPhuLuc, setSelectedPhuLuc] = useState();
  const [selectedIdPhong, setSelectedIdPhong] = useState();

  const [loaiData, setLoaiData] = useState([]);
  const componentRef = useRef();
  const [unload, setUnLoad] = useState(0);

  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1; // Lưu ý tháng trong JavaScript bắt đầu từ 0, nên cần phải cộng thêm 1
  const year = today.getFullYear();

  useEffect(() => {
    // getData();
    getLoai();
  }, []);

  const getLoai = async () => {
    try {
      const result = await axios.get(loaiAPI);
      setLoaiData(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getData = (user) => {
    axios
      .get(
        `${soTuTheoDoiAPI}/inphuluc/${selectedNam}/${selectedPhuLuc}/${user}`
      )
      .then((result) => {
        setData(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching In Phụ Lục Chi Bộ data: ${error}`);
      });
  };

  return (
    <Fragment>
      <ToastContainer />
      <Container>
        <Row className="align-items-center mb-3">
          {/* <Col xs={12} md={1}>
            <label>Năm:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedNam}
              onChange={(e) => {
                setSelectedNam(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn năm</option>
              {(() => {
                const options = [];
                for (let year = 2023; year <= 2040; year++) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          {/* <Col xs={12} md={1}>
            <label>Tháng:</label>
          </Col> */}
          {/* <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedThang}
              onChange={(e) => {
                setSelectedThang(e.target.value);
              }}
            >
              <option value="">Chọn tháng</option>
              {(() => {
                const options = [];
                for (let thang = 1; thang <= 12; thang++) {
                  options.push(
                    <option key={thang} value={thang}>
                      {thang}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col> */}
          {/* <Col xs={12} md={1}>
            <label>Phụ lục:</label>
          </Col> */}
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedPhuLuc}
              onChange={(e) => {
                setSelectedPhuLuc(e.target.value);
                setUnLoad(0);
              }}
            >
              <option value="">Chọn Phụ lục</option>
              {(() => {
                const options = [];
                loaiData.map((loai) => {
                  options.push(
                    <option key={loai.idLoai} value={loai.idLoai}>
                      {loai.phuLuc}
                    </option>
                  );
                });
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={2}>
            <Button
              variant="outline-primary"
              onClick={() => {
                if (selectedNam && selectedPhuLuc) {
                  getData(sessionStorage.getItem("taikhoan"));
                  setUnLoad(1);
                }
              }}
              style={{
                marginRight: "5px",
              }} /* Điều chỉnh khoảng cách bên phải */
            >
              <b>Xem</b>
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button
                  variant="outline-info"
                  hidden={unload === 0 ? true : false}
                >
                  <b>
                    In <Icons.FaPrint />{" "}
                  </b>
                </Button>
              )}
              content={() => componentRef.current} //Sử dụng biến Ref
              bodyClass="print-body"
            />
          </Col>
        </Row>
      </Container>
      <Container style={{ width: "80%" }} hidden={unload === 0 ? true : false}>
        <Table className="print" border={0} ref={componentRef}>
          <tr>
            <td align="left">
              {/* `ĐẢNG BỘ $
              {sessionStorage.getItem("ten_dv")
                ? sessionStorage.getItem("ten_dv").toLocaleUpperCase()
                : ""}
              ` */}
              ĐẢNG BỘ {sessionStorage.getItem("ten_dv").toLocaleUpperCase()}
            </td>
            <td align="right">
              <u>
                <b>ĐẢNG CỘNG SẢN VIỆT NAM</b>
              </u>
            </td>
          </tr>
          <tr>
            <td align="left">
              <b>
                CHI BỘ {sessionStorage.getItem("ten_cb").toLocaleUpperCase()}
              </b>
            </td>

            <td align="right">
              Kiên Giang, ngày {day} tháng {month} năm {year}
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="title">
              <span className="text">
                {selectedPhuLuc === "1"
                  ? "BẢNG TỰ THEO DÕI, ĐÁNH GIÁ 82 BIỂU HIỆN SUY THOÁI \nVỀ TƯ TƯỞNG CHÍNH TRỊ, ĐẠO ĐỨC, LỐI SỐNG; NHỮNG \nBIỂU HIỆN “TỰ DIỄN BIẾN”, “TỰ CHUYỂN HÓA”"
                  : selectedPhuLuc === "2"
                  ? "BẢNG TỰ THEO DÕI\n ĐÁNH GIÁ VIỆC CHẤP HÀNH QUY ĐỊNH NHỮNG ĐIỀU \nĐẢNG VIÊN, CÁN BỘ, CÔNG CHỨC, VIÊN CHỨC \nKHÔNG ĐƯỢC LÀM \n"
                  : selectedPhuLuc === "3"
                  ? "BẢNG TỰ THEO DÕI, ĐÁNH GIÁ \nTHỰC HIỆN VIỆC NÊU GƯƠNG CỦA CÁN BỘ, ĐẢNG VIÊN \n"
                  : ""}
              </span>
            </td>
          </tr>
          <tr>
            <td align="center" colSpan={2} height={35}>
              <hr width={150}></hr>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <Table border={1} className="content">
                <tr className="thead">
                  <td rowspan="2">Tháng Theo dõi</td>
                  <td rowspan="2">
                    {selectedPhuLuc === "1"
                      ? "Không có biểu hiện"
                      : selectedPhuLuc === "2"
                      ? "Không có vi phạm"
                      : selectedPhuLuc === "3"
                      ? "Đã thực hiện tốt các nội dung"
                      : ""}
                  </td>

                  <td colspan="3">Có biểu hiện</td>
                  <td rowspan="2">Ký xác nhận</td>
                </tr>
                <tr className="thead">
                  <td>
                    {selectedPhuLuc === "1"
                      ? "Nội dung biểu hiện"
                      : selectedPhuLuc === "2"
                      ? "Nội dung vi phạm"
                      : selectedPhuLuc === "3"
                      ? "Nội dung vi phạm"
                      : ""}
                  </td>
                  <td>Đã khắc phục</td>
                  <td>Chưa khắc phục</td>
                </tr>

                <tbody>
                  {data && data.length > 0 ? (
                    (() => {
                      const rows = [];
                      for (let i = 1; i <= 12; i++) {
                        if (data.length >= i) {
                          const item = data[i - 1];

                          rows.push(
                            <tr key={i - 1}>
                              <td align="center"> Tháng {item.thang} </td>
                              <td align="center">
                                {item.trangThai != null &&
                                item.trangThai === false
                                  ? "X"
                                  : ""}
                              </td>
                              <td className="custom-text-left">
                                {item.tenNoiDung}{" "}
                              </td>
                              <td align="center">
                                {" "}
                                {item.tinhTrangKhacPhuc === true ? "X" : ""}
                              </td>
                              <td align="center">
                                {" "}
                                {item.tinhTrangKhacPhuc === null ||
                                item.tinhTrangKhacPhuc !== false
                                  ? ""
                                  : "X"}
                              </td>
                              <td align="center"> </td>
                            </tr>
                          );
                        } else {
                          rows.push(
                            <tr key={i}>
                              <td> Tháng {i} </td>
                              <td> </td>
                              <td> </td>
                              <td> </td>
                              <td> </td>
                              <td></td>
                            </tr>
                          );
                        }
                      }

                      return rows;
                    })()
                  ) : (
                    <tr>
                      <td colSpan={6}>Không có dữ liệu</td>
                    </tr>
                  )}

                  <tr>
                    <td align="center">
                      {" "}
                      <b>Kết quả trong năm</b>
                    </td>
                    <td align="center"></td>
                    <td className="custom-text-left"></td>
                    <td align="center"></td>
                    <td align="center"></td>
                    <td align="center"> </td>
                  </tr>
                </tbody>
              </Table>
            </td>
          </tr>
        </Table>
      </Container>
    </Fragment>
  );
};

export default API_InPhuLucDangVien;
