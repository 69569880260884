import React from "react";
// import "../Styles/StyleComponents.scss";
import API_LoaiSuper from "./API_LoaiSuper.js";

function LoaiSuper() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_LoaiSuper />
    </div>
  );
}

export default LoaiSuper;
