import React, { useState, useEffect, Fragment } from "react";

import {
  Table,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Form,
} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Icons from "react-icons/fa"; // Import tất cả biểu tượng từ gói react-icons/fa
import {
  khoaMoSoAPI,
  loaiAPI,
  noiDungAPI,
  soTuTheoDoiAPI,
  noiDungSoTuTheoDoiAPI,
} from "../../services/API_Config";

const API_SoTuTheoDoi = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  const [selectedNam, setSelectedNam] = useState();
  const [selectedThang, setSelectedThang] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedIdPhong, setSelectedIdPhong] = useState();

  const [unload, setUnLoad] = useState(0);
  const [unlock, setUnLock] = useState(0);
  const [trangthai, setTrangThai] = useState([]);

  const [editingIdSoTuTheoDoi, setEditingIdSoTuTheoDoi] = useState("");
  const [editingNam, setEditingNam] = useState("");
  const [editingThang, setEditingThang] = useState("");
  const [editingThoiGianDanhGia, setEditingThoiGianDanhGia] = useState("");
  const [editingKiemDuyet, setEditingKiemDuyet] = useState("");
  const [editingNguoiDung, setEditingNguoiDung] = useState("");

  // const [editidSo, setEditIdSoTuTheoDoi] = useState();
  const [editIdNoiDungSo1, seteditIdNoiDungSo1] = useState();
  // const [editIdLoai1, seteditIdLoai1] = useState();
  const [editNoidung1, seteditNoidung1] = useState();

  const [editIdNoiDungSo2, seteditIdNoiDungSo2] = useState();
  // const [editIdLoai2, seteditIdLoai2] = useState();
  const [editNoidung2, seteditNoidung2] = useState();

  const [editIdNoiDungSo3, seteditIdNoiDungSo3] = useState();
  // const [editIdLoai3, seteditIdLoai3] = useState();
  const [editNoidung3, seteditNoidung3] = useState();

  const [data, setData] = useState([]);
  const [noiDungData1, setNoiDungData1] = useState([]);
  const [noiDungData2, setNoiDungData2] = useState([]);
  const [noiDungData3, setNoiDungData3] = useState([]);
  const [loaiData, setLoaiData] = useState([]);
  const [noiDungSTTD, setNoiDungSTTD] = useState([]);
  const [sttdData, setSttdData] = useState([]);

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const handleRadioChangeLoai1 = (event) => {
    setSelectedValueLoai1(event.target.value); // Cập nhật giá trị khi radio thay đổi
  };
  const handleRadioChangeLoai2 = (event) => {
    setSelectedValueLoai2(event.target.value); // Cập nhật giá trị khi radio thay đổi
  };
  const handleRadioChangeLoai3 = (event) => {
    setSelectedValueLoai3(event.target.value); // Cập nhật giá trị khi radio thay đổi
  };
  const handleRadioChangeTinhTrang1 = (event) => {
    setSelectedValueTinhTrang1(event.target.value); // Cập nhật giá trị khi radio thay đổi
  };
  const handleRadioChangeTinhTrang2 = (event) => {
    setSelectedValueTinhTrang2(event.target.value); // Cập nhật giá trị khi radio thay đổi
  };
  const handleRadioChangeTinhTrang3 = (event) => {
    setSelectedValueTinhTrang3(event.target.value); // Cập nhật giá trị khi radio thay đổi
  };
  const [selectedValueLoai1, setSelectedValueLoai1] = useState("0"); // Radio loai 1
  const [selectedValueLoai2, setSelectedValueLoai2] = useState("0"); // Radio loai 2
  const [selectedValueLoai3, setSelectedValueLoai3] = useState("0"); // Radio loai 3
  const [selectedValueLoaiTinhTrang1, setSelectedValueTinhTrang1] =
    useState("1"); // Radio tình trạng khắc phục loai 1
  const [selectedValueLoaiTinhTrang2, setSelectedValueTinhTrang2] =
    useState("1"); // Radio tình trạng khắc phục loai 2
  const [selectedValueLoaiTinhTrang3, setSelectedValueTinhTrang3] =
    useState("1"); // Radio tình trạng khắc phục loai 3

  const [selectedNoidungForSoTuTheoDoi1, setSelectedNoidungForSoTuTheoDoi1] =
    useState(""); // Điều khiển select chứa nội dung theo loại 1

  const [selectedNoidungForSoTuTheoDoi2, setSelectedNoidungForSoTuTheoDoi2] =
    useState(""); // Điều khiển select chứa nội dung theo loại 2
  const [selectedNoidungForSoTuTheoDoi3, setSelectedNoidungForSoTuTheoDoi3] =
    useState(""); // Điều khiển select chứa nội dung theo loại 3

  const [selectedId1, setSelectedId1] = useState(""); // Value Điều khiển select loại 1
  const [selectedId2, setSelectedId2] = useState(""); // Value Điều khiển select loại 2
  const [selectedId3, setSelectedId3] = useState(""); // Value Điều khiển select loại 3

  const [saveoredit, setSaveOrEdit] = useState(""); //Lưu trạng thái save hay edit

  useEffect(() => {
    // Lây biến session tài khoản; bên Home đã set
    setSelectedUser(sessionStorage.getItem("taikhoan"));
    setSelectedIdPhong(sessionStorage.getItem("id_phongkhoa"));
    //  getNoiDung();
    getLoai();

    // Cập nhật ngày giờ hệ thống mỗi giây
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);
    // Làm sạch interval khi component unmount
    return () => clearInterval(interval);
  }, []);

  const getData = () => {
    axios
      .get(`${soTuTheoDoiAPI}/${selectedNam}/${selectedUser}`)
      .then((result) => {
        setData(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching SoTuTheoDoi data: ${error}`);
      });
  };

  const getNoiDung = (idloai) => {
    return (
      axios
        // .get(`https://localhost:7023/api/NoiDung/theoloai/${idloai}`)
        .get(`${noiDungAPI}/theoloai/${idloai}`)
        .then((result) => {
          console.log(`${noiDungAPI}/theoloai/${idloai}`);
          //  console.log(result.data);
          // setNoiDungData1(result.data);
          return result;
        })
        .catch((error) => {
          console.log(`Error fetching NoiDung data: ${error}`);
        })
    );
  };

  const getLoai = () => {
    axios
      .get(loaiAPI)
      .then((result) => {
        setLoaiData(result.data);
      })
      .catch((error) => {
        console.log(`Error fetching Loai data: ${error}`);
      });
  };

  const getDataKhoaMoSoAll = () => {
    axios
      .get(`${khoaMoSoAPI}/${selectedNam}/${selectedIdPhong}`)
      .then((result) => {
        //Tạo 12 session tương ứng 12 vào cho giá trị 0
        for (let i = 1; i <= 12; i++) {
          sessionStorage.setItem("trangthai" + [i], 0);
        }
        //Gán lại giá trị của session của tháng tương ứng với trạng thái trong csdl
        for (let i = 1; i <= result.data.length; i++) {
          //console.log(result.data[i-1].trangThai);
          sessionStorage.setItem(
            "trangthai" + [result.data[i - 1].thang],
            result.data[i - 1].trangThai ? 1 : 0
          );
        }

        setUnLoad(1);
      })
      .catch((error) => {});
  };

  const getNoiDungSoTuTheoDoi = async (idsotutheodoi) => {
    return axios
      .get(`${noiDungSoTuTheoDoiAPI}/edit/${idsotutheodoi}`)
      .then((result) => {
        console.log(`${noiDungSoTuTheoDoiAPI}/edit/${idsotutheodoi}`);
        //  console.log(result.data);
        // setNoiDungData1(result.data);
        //  setNoiDungSTTD(result.data);
        return result;
      })
      .catch((error) => {
        console.log(`Error fetching NoiDung data: ${error}`);
      });
  };

  const handleOpenDanhGia = async (save, idsttd) => {
    clear();
    setNoiDungSTTD([]);
    // lấy nội dung của loại 1 biểu hiện suy thoái
    let res1 = await getNoiDung(1);
    setNoiDungData1(res1.data);
    // lấy nội dung của loại 2 những điều đảng viên không được làm
    let res2 = await getNoiDung(2);
    setNoiDungData2(res2.data);
    // lấy nội dung của loại 3 nội dung nêu gương
    let res3 = await getNoiDung(3);
    setNoiDungData3(res3.data);

    if (save === false) {
      //Trạng thái edit
      //   console.log(idnd);
      let result = await getNoiDungSoTuTheoDoi(idsttd);
      setNoiDungSTTD(result.data);

      console.log(noiDungSTTD);
      for (let i = 0; i < noiDungSTTD.length; i++) {
        const item = noiDungSTTD[i];
        if (i === 0) {
          seteditIdNoiDungSo1(item.idNoiDungSoTtd);
          setSelectedValueLoai1(item.trangThai ? "1" : "0");
          if (item.trangThai === true) {
            setSelectedValueTinhTrang1(item.tinhTrangKhacPhuc ? "1" : "0");
            setSelectedNoidungForSoTuTheoDoi1(item.tenNoiDung); // Điều khiển select chứa nội dung theo loại 1
            setSelectedId1(item.noiDung); // Value Điều khiển select loại 1
            setOpen1(true);
          }
        }
        if (i === 1) {
          seteditIdNoiDungSo2(item.idNoiDungSoTtd);
          setSelectedValueLoai2(item.trangThai ? "1" : "0");
          if (item.trangThai === true) {
            setSelectedValueTinhTrang2(item.tinhTrangKhacPhuc ? "1" : "0");
            setSelectedNoidungForSoTuTheoDoi2(item.tenNoiDung); // Điều khiển select chứa nội dung theo loại 2
            setSelectedId2(item.noiDung); // Value Điều khiển select loại 2
            setOpen2(true);
          }
        }
        if (i === 2) {
          seteditIdNoiDungSo3(item.idNoiDungSoTtd);
          setSelectedValueLoai3(item.trangThai ? "1" : "0");
          if (item.trangThai === true) {
            setSelectedValueTinhTrang3(item.tinhTrangKhacPhuc ? "1" : "0");
            setSelectedNoidungForSoTuTheoDoi3(item.tenNoiDung); // Điều khiển select chứa nội dung theo loại 3
            setSelectedId3(item.noiDung); // Value Điều khiển select loại 3
            setOpen3(true);
          }
        }
      }
      // console.log(selectedId1);
      // console.log(selectedId2);
      // console.log(selectedId3);
      axios
        .get(`${soTuTheoDoiAPI}/${idsttd}`)
        .then((result) => {
          setSttdData(result.data);
        })
        .catch((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    }

    if (noiDungSTTD.length > 0 || save === true) {
      handleShow();
      setNoiDungSTTD([]);
    }
  };

  const setData123 = (idsttd) => {
    let dataND1 = {};
    if (selectedValueLoai1 === "0") {
      dataND1 = {
        IdNoiDungSoTtd: editIdNoiDungSo1,
        IdSoTuTheoDoi: idsttd,
        IdLoai: 1,
        TrangThai: Boolean(parseInt(selectedValueLoai1)),
      };
    } else {
      dataND1 = {
        IdNoiDungSoTtd: editIdNoiDungSo1,
        IdSoTuTheoDoi: idsttd,
        IdLoai: 1,
        TrangThai: Boolean(parseInt(selectedValueLoai1)),
        NoiDung: parseInt(selectedNoidungForSoTuTheoDoi1),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang1)),
      };
    }

    let dataND2 = {};
    if (parseInt(selectedValueLoai2) === 0) {
      dataND2 = {
        IdNoiDungSoTtd: editIdNoiDungSo2,
        IdSoTuTheoDoi: idsttd,
        IdLoai: 2,
        TrangThai: Boolean(parseInt(selectedValueLoai2)),
      };
    } else {
      dataND2 = {
        IdNoiDungSoTtd: editIdNoiDungSo2,
        IdSoTuTheoDoi: idsttd,
        IdLoai: 2,
        TrangThai: Boolean(parseInt(selectedValueLoai2)),
        NoiDung: parseInt(selectedNoidungForSoTuTheoDoi2),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang2)),
      };
    }

    let dataND3 = {};
    if (parseInt(selectedValueLoai3) === 0) {
      dataND3 = {
        IdNoiDungSoTtd: editIdNoiDungSo3,
        IdSoTuTheoDoi: idsttd,
        IdLoai: 3,
        TrangThai: Boolean(parseInt(selectedValueLoai3)),
      };
    } else {
      dataND3 = {
        IdNoiDungSoTtd: editIdNoiDungSo3,
        IdSoTuTheoDoi: idsttd,
        IdLoai: 3,
        TrangThai: Boolean(parseInt(selectedValueLoai3)),
        NoiDung: parseInt(selectedNoidungForSoTuTheoDoi3),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang3)),
      };
    }
  };

  const handleSave = async () => {
    if (selectedValueLoai1 === "1" && selectedNoidungForSoTuTheoDoi1 === "") {
      toast.error("Bạn phải chọn nội dung biểu hiện suy thoái", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setUnLock(0);
      return false;
    }
    if (selectedValueLoai2 === "1" && selectedNoidungForSoTuTheoDoi2 === "") {
      toast.error("Bạn phải chọn nội dung đảng viên không được làm", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setUnLock(0);
      return false;
    }
    if (selectedValueLoai3 === "1" && selectedNoidungForSoTuTheoDoi3 === "") {
      toast.error("Bạn phải chọn nội dung nêu gương", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      setUnLock(0);
      return false;
    }

    const url = soTuTheoDoiAPI;
    const url2 = noiDungSoTuTheoDoiAPI;
    const dataSTD = {
      Nam: parseInt(selectedNam),
      Thang: selectedThang,
      NguoiDung: sessionStorage.getItem("taikhoan"),
    };

    const res = await axios
      .post(url, dataSTD)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        // console.log(data);
      });

    //  console.log(res.data.idSoTuTheoDoi);

    let dataND1 = {};
    if (selectedValueLoai1 === "0") {
      dataND1 = {
        IdSoTuTheoDoi: res.data.idSoTuTheoDoi,
        IdLoai: 1,
        TrangThai: Boolean(parseInt(selectedValueLoai1)),
      };
    } else {
      dataND1 = {
        IdSoTuTheoDoi: res.data.idSoTuTheoDoi,
        IdLoai: 1,
        TrangThai: Boolean(parseInt(selectedValueLoai1)),
        NoiDung: parseInt(selectedNoidungForSoTuTheoDoi1),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang1)),
      };
    }
    const res1 = await axios
      .post(url2, dataND1)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        // console.log(data);
      });

    let dataND2 = {};
    if (parseInt(selectedValueLoai2) === 0) {
      dataND2 = {
        IdSoTuTheoDoi: res.data.idSoTuTheoDoi,
        IdLoai: 2,
        TrangThai: Boolean(parseInt(selectedValueLoai2)),
      };
    } else {
      dataND2 = {
        IdSoTuTheoDoi: res.data.idSoTuTheoDoi,
        IdLoai: 2,
        TrangThai: Boolean(parseInt(selectedValueLoai2)),
        NoiDung: parseInt(selectedNoidungForSoTuTheoDoi2),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang2)),
      };
    }

    const res2 = await axios
      .post(url2, dataND2)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        // console.log(data);
      });

    let dataND3 = {};
    if (parseInt(selectedValueLoai3) === 0) {
      dataND3 = {
        IdSoTuTheoDoi: res.data.idSoTuTheoDoi,
        IdLoai: 3,
        TrangThai: Boolean(parseInt(selectedValueLoai3)),
      };
    } else {
      dataND3 = {
        IdSoTuTheoDoi: res.data.idSoTuTheoDoi,
        IdLoai: 3,
        TrangThai: Boolean(parseInt(selectedValueLoai3)),
        NoiDung: parseInt(selectedNoidungForSoTuTheoDoi3),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang3)),
      };
    }

    const res3 = await axios
      .post(url2, dataND3)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });

    handleClose();
    if (res && res1 && res2 && res3)
      toast.success("Đã lưu đánh giá thành công.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    getDataKhoaMoSoAll();
    getData();

    clear();
  };

  const handleUpdate = async (editId) => {
    //toast.success("Cập nhật");
    // console.log(selectedValueLoai1);
    // console.log(selectedNoidungForSoTuTheoDoi1);
    if (selectedValueLoai1 === "1" && selectedNoidungForSoTuTheoDoi1 === "") {
      toast.error("Bạn phải chọn nội dung biểu hiện suy thoái", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }
    if (selectedValueLoai2 === "1" && selectedNoidungForSoTuTheoDoi2 === "") {
      toast.error("Bạn phải chọn nội dung đảng viên không được làm", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }
    if (selectedValueLoai3 === "1" && selectedNoidungForSoTuTheoDoi3 === "") {
      toast.error("Bạn phải chọn nội dung nêu gương", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return false;
    }
    // console.log(sttdData);
    // console.log(sttdData.idSoTuTheoDoi);
    // try {
    const url = `${soTuTheoDoiAPI}/${sttdData.idSoTuTheoDoi}`;
    const res = await axios.put(url, sttdData);

    let dataND1 = {};
    if (selectedValueLoai1 === "0") {
      dataND1 = {
        IdNoiDungSoTtd: editIdNoiDungSo1,
        IdSoTuTheoDoi: sttdData.idSoTuTheoDoi,
        IdLoai: 1,
        TrangThai: Boolean(parseInt(selectedValueLoai1)),
      };
    } else {
      dataND1 = {
        IdNoiDungSoTtd: editIdNoiDungSo1,
        IdSoTuTheoDoi: sttdData.idSoTuTheoDoi,
        IdLoai: 1,
        TrangThai: Boolean(parseInt(selectedValueLoai1)),
        NoiDung: isNaN(parseInt(selectedNoidungForSoTuTheoDoi1))
          ? selectedId1
          : parseInt(selectedNoidungForSoTuTheoDoi1),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang1)),
      };
    }
    console.log(`${noiDungSoTuTheoDoiAPI}/${editIdNoiDungSo1}`);
    const url1 = `${noiDungSoTuTheoDoiAPI}/${editIdNoiDungSo1}`;
    const res1 = await axios
      .put(url1, dataND1)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        // console.log(data);
      });

    let dataND2 = {};
    if (parseInt(selectedValueLoai2) === 0) {
      dataND2 = {
        IdNoiDungSoTtd: editIdNoiDungSo2,
        IdSoTuTheoDoi: sttdData.idSoTuTheoDoi,
        IdLoai: 2,
        TrangThai: Boolean(parseInt(selectedValueLoai2)),
      };
    } else {
      dataND2 = {
        IdNoiDungSoTtd: editIdNoiDungSo2,
        IdSoTuTheoDoi: sttdData.idSoTuTheoDoi,
        IdLoai: 2,
        TrangThai: Boolean(parseInt(selectedValueLoai2)),
        NoiDung: isNaN(parseInt(selectedNoidungForSoTuTheoDoi2))
          ? selectedId2
          : parseInt(selectedNoidungForSoTuTheoDoi2),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang2)),
      };
    }
    console.log(`${noiDungSoTuTheoDoiAPI}/${editIdNoiDungSo2}`);
    const url2 = `${noiDungSoTuTheoDoiAPI}/${editIdNoiDungSo2}`;
    const res2 = await axios
      .put(url2, dataND2)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        // console.log(data);
      });

    let dataND3 = {};
    if (parseInt(selectedValueLoai3) === 0) {
      dataND3 = {
        IdNoiDungSoTtd: editIdNoiDungSo3,
        IdSoTuTheoDoi: sttdData.idSoTuTheoDoi,
        IdLoai: 3,
        TrangThai: Boolean(parseInt(selectedValueLoai3)),
      };
    } else {
      dataND3 = {
        IdNoiDungSoTtd: editIdNoiDungSo3,
        IdSoTuTheoDoi: sttdData.idSoTuTheoDoi,
        IdLoai: 3,
        TrangThai: Boolean(parseInt(selectedValueLoai3)),
        NoiDung: isNaN(parseInt(selectedNoidungForSoTuTheoDoi3))
          ? selectedId3
          : parseInt(selectedNoidungForSoTuTheoDoi3),
        TinhTrangKhacPhuc: Boolean(parseInt(selectedValueLoaiTinhTrang3)),
      };
    }
    console.log(`${noiDungSoTuTheoDoiAPI}/${editIdNoiDungSo3}`);
    const url3 = `${noiDungSoTuTheoDoiAPI}/${editIdNoiDungSo3}`;
    const res3 = await axios
      .put(url3, dataND3)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });

    handleClose();
    if (res && res1 && res2 && res3)
      toast.success("Đánh giá đã được cập nhật thành công.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });

    getDataKhoaMoSoAll();
    getData();

    clear();
  };
  const clear = () => {
    seteditIdNoiDungSo1();
    seteditIdNoiDungSo2();
    seteditIdNoiDungSo3();

    setSelectedValueLoai1("0"); // Radio loai 1
    setSelectedValueLoai2("0"); // Radio loai 2
    setSelectedValueLoai3("0"); // Radio loai 3

    setSelectedValueTinhTrang1("1"); // Radio tình trạng khắc phục loai 1
    setSelectedValueTinhTrang2("1"); // Radio tình trạng khắc phục loai 2
    setSelectedValueTinhTrang3("1"); // Radio tình trạng khắc phục loai 3

    setSelectedNoidungForSoTuTheoDoi1(""); // Điều khiển select chứa nội dung theo loại 1
    setSelectedNoidungForSoTuTheoDoi2(""); // Điều khiển select chứa nội dung theo loại 2
    setSelectedNoidungForSoTuTheoDoi3(""); // Điều khiển select chứa nội dung theo loại 3

    setSelectedId1(""); // Value Điều khiển select loại 1
    setSelectedId2(""); // Value Điều khiển select loại 2
    setSelectedId3(""); // Value Điều khiển select loại 3

    setOpen1(false);
    setOpen2(false);
    setOpen3(false);
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}

      {/* Câp nhật */}
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Sổ tự theo dõi cá nhân</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Alert variant="info">
              <Row className="mb-3">
                <div className="p-2">
                  1. Nhận diện, đánh giá các biểu hiện suy thoái, "tự diễn
                  biến", "tự chuyển hóa" và theo dõi, đánh giá việc chấp hành
                  quy định những điều Cán bộ, Đảng viên không được làm
                </div>
                {/* <div className="p-2">
                  {loaiData.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </div> */}
                <Col xs={3}>
                  <Form.Check
                    type="radio"
                    label="Không có biểu hiện"
                    name="loai1"
                    value="0"
                    checked={selectedValueLoai1 === "0"}
                    onChange={handleRadioChangeLoai1}
                    onClick={() => setOpen1(false)}
                    aria-controls="noidung1"
                    aria-expanded={open1}
                  />
                </Col>
                <Col xs={9}>
                  <Form.Check
                    type="radio"
                    label="Có biểu hiện"
                    name="loai1"
                    value="1"
                    checked={selectedValueLoai1 === "1"}
                    onChange={handleRadioChangeLoai1}
                    onClick={() => setOpen1(true)}
                    aria-controls="noidung1"
                    aria-expanded={open1}
                  />
                  <Collapse in={open1}>
                    <div id="noidung1">
                      <hr style={{ borderTop: "2px solid black" }} />
                      <label>Nội dung biểu hiện:</label>
                      <select
                        className="form-select mb-3"
                        value={selectedNoidungForSoTuTheoDoi1}
                        defaultValue={selectedId1 > 0 ? selectedId1 : ""}
                        onChange={(e) =>
                          setSelectedNoidungForSoTuTheoDoi1(e.target.value)
                        }
                      >
                        <option value="">--Chọn--</option>
                        {noiDungData1.map((noiDung) => (
                          <option
                            key={noiDung.idNoiDung}
                            value={noiDung.idNoiDung}
                          >
                            {noiDung.tenNoiDung}
                          </option>
                        ))}
                      </select>
                      <label>Tình trạng khắc phục:</label>
                      <Form.Check
                        type="radio"
                        label="Đã khắc phục"
                        name="khacPhucLoai1"
                        value="1"
                        checked={selectedValueLoaiTinhTrang1 === "1"}
                        onChange={handleRadioChangeTinhTrang1}
                      />
                      <Form.Check
                        type="radio"
                        label="Chưa khắc phục"
                        name="khacPhucLoai1"
                        value="0"
                        checked={selectedValueLoaiTinhTrang1 === "0"}
                        onChange={handleRadioChangeTinhTrang1}
                      />
                    </div>
                  </Collapse>
                </Col>
              </Row>
            </Alert>
            <Alert variant="info">
              <Row className="mb-3">
                <div className="p-2">
                  2. Những điều Đảng viên, Cán bộ, Viên chức, Công chức không
                  được làm
                </div>

                {/* {loaiData[0].map((item, index) => (
                  <div key={index}>{item}</div>
                ))} */}
                <Col xs={3}>
                  <Form.Check
                    type="radio"
                    label="Không có vi phạm"
                    name="loai2"
                    value="0"
                    checked={selectedValueLoai2 === "0"}
                    onChange={handleRadioChangeLoai2}
                    onClick={() => setOpen2(false)}
                    aria-controls="example-collapse-text"
                    aria-expanded={open2}
                  />
                </Col>
                <Col xs={9}>
                  <Form.Check
                    type="radio"
                    label="Có vi phạm"
                    name="loai2"
                    value="1"
                    checked={selectedValueLoai2 === "1"}
                    onChange={handleRadioChangeLoai2}
                    onClick={() => setOpen2(true)}
                    aria-controls="noidung2"
                    aria-expanded={open2}
                  />
                  <Collapse in={open2}>
                    <div id="noidung2">
                      <hr style={{ borderTop: "2px solid black" }} />
                      <label>Nội dung biểu hiện:</label>
                      <select
                        className="form-select mb-3"
                        //value={selectedNoidungForSoTuTheoDoi2}
                        defaultValue={selectedId2 > 0 ? selectedId2 : ""}
                        onChange={(e) =>
                          setSelectedNoidungForSoTuTheoDoi2(e.target.value)
                        }
                      >
                        <option value="">--Chọn--</option>

                        {noiDungData2.map((noiDung) => (
                          <option
                            key={noiDung.idNoiDung}
                            value={noiDung.idNoiDung}
                          >
                            {noiDung.tenNoiDung}
                          </option>
                        ))}
                      </select>
                      <label>Tình trạng khắc phục:</label>
                      <Form.Check
                        type="radio"
                        label="Đã khắc phục"
                        name="khacPhucLoai2"
                        value="1"
                        checked={selectedValueLoaiTinhTrang2 === "1"}
                        onChange={handleRadioChangeTinhTrang2}
                      />
                      <Form.Check
                        type="radio"
                        label="Chưa khắc phục"
                        name="khacPhucLoai2"
                        value="0"
                        checked={selectedValueLoaiTinhTrang2 === "0"}
                        onChange={handleRadioChangeTinhTrang2}
                      />
                    </div>
                  </Collapse>
                </Col>
              </Row>
            </Alert>
            <Alert variant="info">
              <Row className="mb-3">
                <div className="p-2">
                  3. Theo dõi, đánh giá việc thực hiện nêu gương của Cán bộ,
                  Đảng viên
                </div>

                {/* {loaiData[0].map((item, index) => (
                  <div key={index}>{item}</div>
                ))} */}
                <Col xs={3}>
                  <Form.Check
                    type="radio"
                    label="Đã thực hiện tốt các nội dung"
                    name="loai3"
                    value="0"
                    checked={selectedValueLoai3 === "0"}
                    onChange={handleRadioChangeLoai3}
                    onClick={() => setOpen3(false)}
                    aria-controls="noidung3"
                    aria-expanded={open3}
                  />
                </Col>
                <Col xs={9}>
                  <Form.Check
                    type="radio"
                    label="Có vi phạm"
                    name="loai3"
                    value="1"
                    checked={selectedValueLoai3 === "1"}
                    onChange={handleRadioChangeLoai3}
                    onClick={() => setOpen3(true)}
                    aria-controls="noidung3"
                    aria-expanded={open3}
                  />
                  <Collapse in={open3}>
                    <div id="noidung3">
                      <hr style={{ borderTop: "2px solid black" }} />
                      <label>Nội dung biểu hiện:</label>
                      <select
                        className="form-select mb-3"
                        // value={selectedNoidungForSoTuTheoDoi3}
                        defaultValue={selectedId3 > 0 ? selectedId3 : ""}
                        onChange={(e) =>
                          setSelectedNoidungForSoTuTheoDoi3(e.target.value)
                        }
                      >
                        <option value="">--Chọn--</option>
                        {noiDungData3.map((noiDung) => (
                          <option
                            key={noiDung.idNoiDung}
                            value={noiDung.idNoiDung}
                          >
                            {noiDung.tenNoiDung}
                          </option>
                        ))}
                      </select>
                      <label>Tình trạng khắc phục:</label>
                      <Form.Check
                        type="radio"
                        label="Đã khắc phục"
                        name="khacPhucLoai3"
                        value="1"
                        checked={selectedValueLoaiTinhTrang3 === "1"}
                        onChange={handleRadioChangeTinhTrang3}
                      />
                      <Form.Check
                        type="radio"
                        label="Chưa khắc phục"
                        name="khacPhucLoai3"
                        value="0"
                        checked={selectedValueLoaiTinhTrang3 === "0"}
                        onChange={handleRadioChangeTinhTrang3}
                      />
                    </div>
                  </Collapse>
                </Col>
              </Row>
            </Alert>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose()}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (saveoredit === "save") {
                setUnLock(1);
                handleSave();
              } else {
                setUnLock(1);
                handleUpdate();
              }
            }}
            disabled={unlock === 1 ? true : false}
          >
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>

      <Container>
        <Row className="align-items-center mb-3">
          <Col xs={12} md={1}>
            <label>Năm:</label>
          </Col>
          <Col xs={12} md={2}>
            <select
              className="form-select"
              value={selectedNam}
              onChange={(e) => {
                setSelectedNam(e.target.value);
              }}
            >
              <option value="">Chọn năm</option>
              {(() => {
                const options = [];
                for (let year = 2023; year <= 2040; year++) {
                  options.push(
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                }
                return options;
              })()}
            </select>
          </Col>
          <Col xs={12} md={1}>
            <Button
              variant="outline-primary"
              onClick={() => {
                getDataKhoaMoSoAll();
                getData();
              }}
            >
              Xem{" "}
            </Button>{" "}
          </Col>
        </Row>
      </Container>

      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>Tháng</th>
            <th>Trạng thái đánh giá</th>
            <th>Thời gian đánh giá</th>
            <th>Kiểm duyệt</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0
            ? (() => {
                const rows = [];
                for (let i = 1; i <= 12; i++) {
                  let found = false;
                  if (parseInt(sessionStorage.getItem("trangthai" + [i])) === 1)
                    //   console.log(
                    //     i + " = " + sessionStorage.getItem("trangthai" + [i])
                    //   );
                    for (let index = 0; index < data.length; index++) {
                      const item = data[index];
                      if (item.thang === i) {
                        if (
                          parseInt(
                            sessionStorage.getItem("trangthai" + [i])
                          ) === 1
                        ) {
                          rows.push(
                            <tr key={i}>
                              <td> {i} </td>
                              <td> {item.thang} </td>
                              <td> Đã thực hiện </td>
                              <td> {item.thoiGianDanhGia} </td>
                              <td> {item.kiemDuyet} </td>
                              <td>
                                <button
                                  className="btn btn-outline-primary"
                                  disabled={
                                    item.kiemDuyet !== null ? true : false
                                  }
                                  onClick={() => {
                                    // console.log(item.idSoTuTheoDoi);
                                    //setEditIdSoTuTheoDoi(item.idSoTuTheoDoi);
                                    // console.log(editidsotutheodoi);
                                    setSelectedThang(i);
                                    setSaveOrEdit("edit");
                                    setUnLock(0);
                                    handleOpenDanhGia(
                                      false,
                                      item.idSoTuTheoDoi
                                    );
                                  }}
                                  //handleEdit(item.idSoTuTheoDoi)}
                                >
                                  <Icons.FaPen />{" "}
                                </button>{" "}
                              </td>
                            </tr>
                          );
                          found = true;
                        } else {
                          rows.push(
                            <tr key={i}>
                              <td> {i} </td>
                              <td> {item.thang} </td>
                              <td> Đã thực hiện</td>
                              <td> {item.thoiGianDanhGia} </td>
                              <td> {item.kiemDuyet} </td>
                              <td>
                                <button
                                  className="btn btn-outline-danger"
                                  disabled
                                >
                                  <Icons.FaPen />{" "}
                                </button>{" "}
                              </td>
                            </tr>
                          );
                          found = true;
                        }
                      }
                    }
                  if (found === false) {
                    if (
                      parseInt(sessionStorage.getItem("trangthai" + [i])) === 1
                    ) {
                      rows.push(
                        <tr key={i}>
                          <td> {i} </td>
                          <td> {i} </td>
                          <td> Chưa thực hiện </td>
                          <td> </td>
                          <td> </td>
                          <td>
                            <button
                              className="btn btn-outline-primary"
                              onClick={() => {
                                setSelectedThang(i);
                                setSaveOrEdit("save");
                                setUnLock(0);
                                handleOpenDanhGia(true, 0); // true là mở đánh giá chế độ để lưu
                              }} //Chưa thực hiện nhưng đã mở gọi save
                              // trang thái false (khong bieu hien) noi dung null, tinh trang null
                            >
                              <Icons.FaPen />{" "}
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      rows.push(
                        <tr key={i}>
                          <td> {i} </td>
                          <td> {i} </td>
                          <td> Chưa thực hiện </td>
                          <td> </td>
                          <td> </td>
                          <td>
                            <button className="btn btn-outline-danger" disabled>
                              <Icons.FaPen />{" "}
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  }
                }
                return rows;
              })()
            : (() => {
                const rows = [];
                if (unload !== 0) {
                  for (let i = 1; i <= 12; i++) {
                    if (
                      parseInt(sessionStorage.getItem("trangthai" + [i])) === 1
                    ) {
                      rows.push(
                        <tr key={i}>
                          <td> {i} </td>
                          <td> {i} </td>
                          <td> Chưa thực hiện </td>
                          <td> </td>
                          <td> </td>
                          <td>
                            <button
                              className="btn btn-outline-primary"
                              onClick={() => {
                                setSelectedThang(i);
                                setSaveOrEdit("save");
                                setUnLock(0);
                                handleOpenDanhGia(true, 0);
                              }}
                            >
                              <Icons.FaPen />{" "}
                            </button>
                          </td>
                        </tr>
                      );
                    } else {
                      rows.push(
                        <tr key={i}>
                          <td> {i} </td>
                          <td> {i} </td>
                          <td> Chưa thực hiện </td>
                          <td> </td>
                          <td> </td>
                          <td>
                            <button className="btn btn-outline-danger" disabled>
                              <Icons.FaPen />{" "}
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  }
                  return rows;
                }
              })()}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default API_SoTuTheoDoi;
