import React from "react";
import "../Styles/StyleComponents.scss";
import API_PhanQuyen from "./API_PhanQuyen";

function PhanQuyen() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_PhanQuyen />
    </div>
  );
}

export default PhanQuyen;
