// Điều hướng
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../Auth/Login";
import Home from "./Home";
import QuenMatKhau from "../QuenMatKhau/QuenMatKhau";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="App">
          <ToastContainer position="top-center" />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/QuenMatKhau" element={<QuenMatKhau />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
