import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { donViAPI, phongKhoaAPI } from "../../services/API_Config";

const API_PhongKhoa = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [tenPhongKhoa, setTenPhongKhoa] = useState("");
  const [id_donvi, setId_Donvi] = useState("");

  const [editIdPhongKhoa, setEditIdPhongKhoa] = useState("");
  const [editTenPhongKhoa, setEditTenPhongKhoa] = useState("");

  const [donViDataView, setDonViDataView] = useState({}); // Lấy tên đơn vị trong bảng phòng khoa để xử lý hiển thị
  const [donViData, setDonViData] = useState([]); // Lấy tên đơn vị trong bảng phòng khoa để xử lý thêm, sửa

  const [selectedDonViForPhongKhoa, setSelectedDonViForPhongKhoa] =
    useState("");

  // Mảng chứa dữ liệu Phòng khoa được lấy từ API. Ban đầu, nó sẽ được set là một mảng trống.
  const [data, setData] = useState([]);

  // Hook này được sử dụng để gọi hàm getData khi component được render lần đầu tiên
  useEffect(() => {
    setId_Donvi(sessionStorage.getItem("id_dv"));
    console.log(id_donvi);
    getData(); // Gọi API lấy dữ liệu Phòng khoa
    getDonVi(); // Gọi API lấy danh sách đơn vị
  }, []);

  const getData = () => {
    axios
      .get(`${phongKhoaAPI}/dschibo/${sessionStorage.getItem("id_dv")}`)
      .then((result) => {
        setData(result.data);
        // Lấy thông tin đơn vị cho mỗi bản ghi chi bộ
        result.data.forEach((phongKhoa) => {
          axios
            .get(`${donViAPI}/${phongKhoa.idDonVi}`)
            .then((donViResult) => {
              setDonViDataView((prevData) => ({
                ...prevData,
                [phongKhoa.idPhongKhoa]: donViResult.data,
              }));
              //console.log(`${phongKhoaAPI}/dschibo/${id_donvi}`);
            })
            .catch((error) => {
              console.log(error);
            });
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Hàm lấy data của đơn vị
   */
  const getDonVi = () => {
    axios
      .get(donViAPI)
      .then((result) => {
        setDonViData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    handleShowThem();
    if (!tenPhongKhoa) {
      // toast.error("Vui lòng nhập tên chi bộ và chọn đơn vị.");
      return;
    }

    const url = phongKhoaAPI;
    const data = {
      TenPhongKhoa: tenPhongKhoa,
      IdDonVi: sessionStorage.getItem("id_dv"),
    };

    axios
      .post(url, data)
      .then((result) => {
        if (result.status === 201) {
          getDonVi(); // Gọi hàm mới để cập nhật danh sách đơn vị
          getData(); // Cập nhật danh sách chi bộ và dữ liệu khác
          clear();
          setSelectedDonViForPhongKhoa(""); // Đặt đơn vị thành lựa chọn rỗng sau khi thêm
          toast.success("chi bộ đã được thêm", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleCloseThem();
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  /**
   * Khi người dùng muốn sửa thông tin của một chi bộ,
   * hàm này sẽ gửi yêu cầu HTTP GET để lấy thông tin chi tiết của chi bộ dựa vào id được truyền vào.
   * Thông tin này sẽ được hiển thị trong modal để người dùng chỉnh sửa.
   */
  const handleEdit = (idPhongKhoa) => {
    handleShow();
    axios
      .get(`${phongKhoaAPI}/${idPhongKhoa}`)
      .then((result) => {
        setEditIdPhongKhoa(idPhongKhoa);
        setEditTenPhongKhoa(result.data.tenPhongKhoa);
        setSelectedDonViForPhongKhoa(result.data.idDonVi); // Chọn tên đơn vị
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  /**
   * Khi người dùng hoàn tất việc chỉnh sửa thông tin của chi bộ trong modal,
   * hàm này sẽ gửi yêu cầu HTTP PUT để cập nhật thông tin đã sửa.
   */
  const handleUpdate = async () => {
    try {
      const url = `${phongKhoaAPI}/${editIdPhongKhoa}`;
      const data = {
        idPhongKhoa: editIdPhongKhoa,
        tenPhongKhoa: editTenPhongKhoa,
        idDonVi: sessionStorage.getItem("id_dv"),
      };

      const result = await axios.put(url, data);
      if (result.status === 200) {
        handleClose(); // Đóng hộp cập nhật
        getData();
        clear();
        toast.success("chi bộ đã được Cập nhật", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      }); // Sử dụng error.message để hiển thị thông báo lỗi cụ thể
    }
  };

  /**
   * Khi người dùng muốn xóa tạm một chi bộ (quản trị thường),
   * hàm này sẽ gửi yêu cầu HTTP PUT dựa vào id của chi bộ.
   * Trước khi xóa, một hộp thoại xác nhận sẽ được hiển thị để người dùng xác nhận.
   */
  const handleDelete = async (idPhongKhoa) => {
    const shouldDelete = window.confirm("Bạn chắc chắn muốn xóa?");

    if (!shouldDelete) {
      return;
    }

    const url = `${phongKhoaAPI}/${idPhongKhoa}`;

    try {
      // Lấy index của chi bộ trong mảng data
      const index = data.findIndex((item) => item.idPhongKhoa === idPhongKhoa);

      if (index !== -1) {
        // Tạo một bản sao của đối tượng chi bộ để thực hiện cập nhật trạng thái xóa
        const updatedPhongKhoa = { ...data[index], xoa: true };

        // Gửi yêu cầu cập nhật lên server
        const response = await axios.put(url, updatedPhongKhoa);

        if (response.status === 200) {
          // Cập nhật lại mảng data sau khi xóa thành công
          const updatedData = [...data];
          updatedData[index] = updatedPhongKhoa;

          // Cập nhật state hoặc gọi hàm để cập nhật dữ liệu
          setData(updatedData); // Giả sử setData là hàm cập nhật state
          getData(); // Load lại data (chỉ hiển thị phong khoa có cột xóa là false)

          // Hiển thị thông báo thành công
          toast.success("chi bộ đã được đánh dấu xóa", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      toast.error("Đã xảy ra lỗi khi xóa chi bộ", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  /**
   * Hàm này dùng để xóa rỗng các trường dữ liệu nhập liệu và các biến trạng thái liên quan sau khi hoàn tất thêm hoặc sửa.
   */
  const clear = () => {
    setTenPhongKhoa("");
    setEditIdPhongKhoa(0);
    setEditTenPhongKhoa("");
    setSelectedDonViForPhongKhoa(""); // Đặt đơn vị thành lựa chọn rỗng sau khi hoàn tất thêm hoặc sửa
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Modal show={showThem} onHide={handleCloseThem}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm chi bộ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập chi bộ vào"
                  value={tenPhongKhoa}
                  onChange={(e) => setTenPhongKhoa(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThem}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!tenPhongKhoa}
          >
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row>
          <Col xs={12} md={2}>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleSave()}
            >
              Thêm chi bộ
            </button>
          </Col>
        </Row>
      </Container>
      <br />
      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>ID</th>
            <th>Tên chi bộ</th>
            <th>Đơn vị</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {item.idPhongKhoa} </td>
                  <td className="custom-text-left"> {item.tenPhongKhoa} </td>
                  <td className="custom-text-left">
                    {donViDataView[item.idPhongKhoa]?.tenDonVi || ""}{" "}
                    {/* Hiển thị tên đơn vị */}
                  </td>
                  <td colSpan={2}>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => handleEdit(item.idPhongKhoa)}
                    >
                      Sửa{" "}
                    </button>

                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(item.idPhongKhoa)}
                    >
                      Xóa
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={5}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa chi bộ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập tên vào"
                  value={editTenPhongKhoa}
                  onChange={(e) => setEditTenPhongKhoa(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_PhongKhoa;
