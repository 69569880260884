import React from "react";
import "../Styles/StyleComponents.scss";
import API_ThayDoiMatKhau from "././API_ThayDoiMatKhau";

function ThayDoiMatKhau() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_ThayDoiMatKhau />
    </div>
  );
}

export default ThayDoiMatKhau;
