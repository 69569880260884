import React from "react";
import "../Styles/StyleComponents.scss";
import API_SoTuTheoDoi from "./API_SoTuTheoDoi";

function SoTuTheoDoi() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_SoTuTheoDoi />
    </div>
  );
}

export default SoTuTheoDoi;
