import React from "react";
import "../Styles/StyleComponents.scss";
import API_TaiKhoanSuper from "./API_TaiKhoanSuper";

function TaiKhoanSuper() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_TaiKhoanSuper />
    </div>
  );
}

export default TaiKhoanSuper;
