import React from "react";
import "../Styles/StyleComponents.scss";
import API_KiemDuyet from "./API_KiemDuyet";

function KiemDuyet() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_KiemDuyet />
    </div>
  );
}

export default KiemDuyet;
