import React, { useState, useEffect, Fragment } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { phanQuyenAPI } from "../../services/API_Config";

const API_PhanQuyen = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showThem, setShowThem] = useState(false);
  const handleCloseThem = () => setShowThem(false);
  const handleShowThem = () => setShowThem(true);

  const [quyen, setQuyen] = useState("");

  const [editIdQuyen, setEditIdQuyen] = useState("");
  const [editQuyen, setEditQuyen] = useState("");

  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios
      .get(phanQuyenAPI)
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSave = () => {
    handleShowThem();
    if (!quyen) {
      // toast.error("Vui lòng nhập quyền.");
      return;
    }
    const url = phanQuyenAPI;
    const data = {
      Quyen: quyen,
    };

    axios
      .post(url, data)
      .then((result) => {
        if (result.status === 200) {
          getData();
          clear();
          toast.success("Quyền đã được thêm", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          handleCloseThem();
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const handleEdit = (idQuyen) => {
    handleShow();
    axios
      .get(`${phanQuyenAPI}/${idQuyen}`)
      .then((result) => {
        setEditQuyen(result.data.quyen);
        setEditIdQuyen(idQuyen);
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  const handleUpdate = () => {
    handleShow();
    const url = `${phanQuyenAPI}/${editIdQuyen}`;
    const data = {
      Quyen: editQuyen,
      idQuyen: editIdQuyen,
    };

    axios
      .put(url, data)
      .then((result) => {
        if (result.status === 200) {
          handleClose();
          getData();
          clear();
          toast.success("Quyền đã được cập nhật", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch((error) => {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };

  /**
   * Hàm này dùng để xóa thật sự xuống database
   */
  const handleDelete = (idPhanQuyen) => {
    if (window.confirm("Bạn chắc chắn muốn xóa?")) {
      axios
        .delete(`${phanQuyenAPI}/${idPhanQuyen}`)
        .then((result) => {
          if (result.status === 200) {
            // Success
            toast.success("Phân quyền đã được xóa", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            getData();
          }
        })
        .catch((error) => {
          toast.error(error, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    }
  };

  // const handleDelete = (idQuyen) => {
  //   if (window.confirm("Bạn chắc chắn muốn xóa?")) {
  //     const url = `https://localhost:7023/api/PhanQuyen/${idQuyen}`;

  //     const quyenToDelete = data.find((item) => item.idQuyen === idQuyen);

  //     if (quyenToDelete) {
  //       quyenToDelete.xoa = true;

  //       axios
  //         .put(url, quyenToDelete)
  //         .then((result) => {
  //           if (result.status === 200) {
  //             toast.success("Quyền đã được đánh dấu xóa");
  //             getData();
  //           }
  //         })
  //         .catch((error) => {
  //           toast.error(error);
  //         });
  //     }
  //   }
  // };

  const clear = () => {
    setQuyen("");
    setEditQuyen("");
    setEditIdQuyen(0);
  };

  return (
    <Fragment>
      {/* <ToastContainer /> */}
      <Modal show={showThem} onHide={handleCloseThem}>
        <Modal.Header closeButton>
          <Modal.Title>Thêm quyền</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row className="mb-3">
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập quyền vào"
                  value={quyen}
                  onChange={(e) => setQuyen(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseThem}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={!quyen}>
            Thêm quyền
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={2}>
            <button
              className="btn btn-outline-primary btn-block"
              onClick={() => handleSave()}
            >
              Thêm quyền
            </button>
          </Col>
        </Row>
      </Container>
      <br />
      <Table striped bordered hover className="responsive-table">
        <thead>
          <tr>
            <th>STT</th>
            <th>ID</th>
            <th>Quyền</th>
            <th>Thao tác</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td> {item.idQuyen} </td>
                  <td className="custom-text-left"> {item.quyen} </td>
                  <td colSpan={2}>
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => handleEdit(item.idQuyen)}
                    >
                      Sửa{" "}
                    </button>
                    <button
                      className="btn btn-outline-danger"
                      onClick={() => handleDelete(item.idQuyen)}
                    >
                      Xóa
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>Loading ...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Chỉnh sửa quyền</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nhập quyền vào"
                  value={editQuyen}
                  onChange={(e) => setEditQuyen(e.target.value)}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button variant="primary" onClick={handleUpdate}>
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default API_PhanQuyen;
