import React from "react";
import "../Styles/StyleComponents.scss";
import API_TaiKhoan from "./API_TaiKhoan";

function TaiKhoan() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_TaiKhoan />
    </div>
  );
}

export default TaiKhoan;
