import React from "react";
import "../Styles/StyleComponents.scss";
import "../Styles/InPhuLuc.css";
import API_InPhuLucChiBo from "./API_InPhuLucChiBo";

function InPhuLucChiBo() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_InPhuLucChiBo />
    </div>
  );
}

export default InPhuLucChiBo;
