import React from "react";
import "../Styles/StyleComponents.scss";
import "../Styles/InPhuLuc.css";
import API_InPhuLucDangVien from "./API_InPhuLucDangVien";

function InPhuLucDangVien() {
  return (
    <div className="App noi-dung-container custom-centered-table">
      <API_InPhuLucDangVien />
    </div>
  );
}

export default InPhuLucDangVien;
